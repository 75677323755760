<template>
    <div class="panel inline">
      <ConfirmModal v-if="tagForDeletion" item-type="Tag" :item-name="tagForDeletion.tag" :confirm="confirmDeleteTag" :cancel="cancelDeleteTag" />
      <ListPanel title="Tags" :fields="fields" :items="allTags" :on-add="addNewTag" :on-delete="askToDeleteTag" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "Tags",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      tagForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px"},
        {label: "Tag", prop: "tag", primary: true, sortable: true},
        {label: "Type", prop: "type", width: "10%", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allTags']),
  },

  methods: {
    ...mapActions(['getTags','setLoading','deleteTag']),
    goto(tag) {
      this.$router.push('/tag/'+tag.id)
    },
    addNewTag() {
      this.$router.push('/tag/new')
    },
    askToDeleteTag(tag) {
      this.tagForDeletion = tag;
    },
    cancelDeleteTag() { this.tagForDeletion = null },
    confirmDeleteTag() {
      this.deleteTag(this.tagForDeletion.id)
      this.tagForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allTags.length <= 0) await this.getTags();
      },800)
    }
    else if (this.allTags.length <= 0) await this.getTags();
  },
}
</script>
