import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        recipes : []
    },
    getters : {
        allRecipes: state => state.recipes
    },
    actions : {
        async getRecipes({commit}) {
            const recipes = await ApiRequest(ApiCall(Api.ListRecipes));
            commit('setRecipes', recipes);
        },
        async updateRecipe({commit}, [recipe, id]) {
            await ApiRequest(ApiCall(Api.UpdateRecipe, recipe, id))
            commit('saveRecipe', [recipe, id])
        },
        async createRecipe({commit}, recipe) {
            const res = await ApiRequest(ApiCall(Api.CreateRecipe, recipe))
            recipe.id = res.insertId
            commit('saveRecipe', [recipe, null])
        },
        async deleteRecipe({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteRecipe, id))
            commit('deleteRecipe', id)
        },

    },
    mutations : {
        setRecipes: (state, recipes) => state.recipes = recipes,
        saveRecipe: (state, [recipe, id]) => {
            if (id) {
                state.recipes = state.recipes.map(it => it.id === recipe.id ? recipe : it)
            }
            else {
                state.recipes = [...state.recipes, recipe];
            }
            return state
        },
        deleteRecipe(state, id) {
            state.recipes = state.recipes.filter(it => it.id !== id)
        },
    }
}
