<template>
  <div v-if="tag" class="panel inline">
    <div class="panel-header">
      <p>Tag: <b>{{tag.tag}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="tag" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="tag.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" name-field="tag" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ tag.id?'Update Tag':'Create Tag' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import tagFields from "@/lib/form-fields/tag";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";

const localeFields = [
  { name:"tag" , label: "Tag", type: "text" }
];

export default {

  components: {LocaleForm, AppForm },

  data() {
    return {
      fields : tagFields,
      localeFields,
      tag : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "Tag",
  computed: {
    ...mapGetters(['isLoggedIn', 'allTags', 'allTags','allServingSizes','allLocales']),
  },
  methods: {
    ...mapActions(['getTags','getTags','setLoading','setSuccess','getServingSizes','getLocales','updateTag','createTag']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.tag.id) { //update
        await this.updateTag([this.tag, this.tag.id])
      }
      else { // create new
        await this.createTag(this.tag);
        if (this.tag.id) {
          await this.$router.push('/tag/'+this.tag.id)
          await this.initData()
          this.tag = {...this.tag }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Tag saved");
    },

    onUpdate(values) {
      this.tag = {...this.tag, ...values};
    },

    async initData() {
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.tag = !isNaN(this.$route.params.id*1) ? this.allTags.find(it => it.id === this.$route.params.id*1) :
          {tag: "new-tag"}

      if (this.tag.id) await this.getTagLocales()
    },

    async getTagLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetTagLocales, this.tag.id))
    },

    async createTagLocale(tag_id, locale_id, tag) {
      return await ApiRequest(ApiCall(Api.CreateTagLocale, { locale_id, tag },  tag_id))
    },
    async updateTagLocale(tag_id, locale_id, tag) {
      return await ApiRequest(ApiCall(Api.UpdateTagLocale, {tag},  tag_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({tag, id = null}, locale_id) {
      if (id) {
        await this.updateTagLocale(this.tag.id, locale_id, tag)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, tag} : it)
      }
      else {
        const res = await this.createTagLocale(this.tag.id, locale_id, tag)
        this.locales.push({id: res.insertId, locale_id, tag})
      }
      this.settingLocale = null
    }
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
