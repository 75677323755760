<template>
    <div class="panel inline">
      <ConfirmModal v-if="marketForDeletion" item-type="Market" :item-name="marketForDeletion.name" :confirm="confirmDeleteMarket" :cancel="cancelDeleteMarket" />
      <ListPanel title="Markets" :fields="fields" :items="allMarkets" :on-add="addNewMarket" :on-delete="askToDeleteMarket" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "Markets",

  data() {
    return {
      marketForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", width: "20%", primary: true, sortable: true},
        {label: "Description", prop: "description", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMarkets']),
  },

  methods: {
    ...mapActions(['getMarkets','setLoading','deleteMarket']),
    goto(market) {
      this.$router.push('/market/'+market.id)
    },
    addNewMarket() {
      this.$router.push('/market/new')
    },
    askToDeleteMarket(market) {
      this.marketForDeletion = market;
    },
    cancelDeleteMarket() { this.marketForDeletion = null },
    confirmDeleteMarket() {
      this.deleteMarket(this.marketForDeletion.id)
      this.marketForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allMarkets.length <= 0) await this.getMarkets();
      },800)
    }
    else if (this.allMarkets.length <= 0) await this.getMarkets();
  },
  components: {ListPanel, ConfirmModal }
}
</script>
