<template>
  <div class="panel inline">
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Plan Settings</p>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <SideMenu :items="menuItems" :path="path" @change="onMenuItem" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="full-scroller">
          <GeneralSettings v-if="path==='/plan-settings'" />
          <MealTypes v-if="path==='/plan-settings/meal-types'" />
          <PlanMealStyles v-if="path==='/plan-settings/meal-styles'" />
          <MenuRules v-if="path==='/plan-settings/menu-rules'" />
          <MenuInputs v-if="path==='/plan-settings/menu-inputs'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/ui/SideMenu";
import GeneralSettings from "@/components/views/Plan/PlanSettings/GeneralSettings";
import MealTypes from "@/components/views/Plan/PlanSettings/MealTypes";
import MenuRules from "@/components/views/Plan/PlanSettings/MenuRules";
import MenuInputs from "@/components/views/Plan/PlanSettings/MenuInputs";
import PlanMealStyles from "@/components/views/Plan/PlanSettings/PlanMealStyles";

const menuItems = [
  { path: '/plan-settings', label: "General Settings" },
  { path: '/plan-settings/meal-types', label: "Meal Types" },
  { path: '/plan-settings/meal-styles', label: "Meal Styles" },
  { path: '/plan-settings/menu-rules', label: "Menu Rules" },
  { path: '/plan-settings/menu-inputs', label: "Menu Input Parameters" },
]

export default {
  name: "PlanSettings",

  components: {PlanMealStyles, MenuInputs, MenuRules, MealTypes, SideMenu, GeneralSettings},

  data: function () {
    return {
      menuItems,
      path: ''
    }
  },

  async mounted() {

    this.path = this.$router.currentRoute.path;
  },
  async updated() {

  },

  methods : {
    onMenuItem(item) {
      this.path = item.path;
      if (item.path !== this.$router.currentRoute.path) this.$router.push(item.path)
    },
  },

  watch : {
    $route() {
      this.path = this.$router.currentRoute.path;
    }
  },



}

</script>
