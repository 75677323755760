<template>
  <div v-if="servingSize" class="panel inline">
    <div class="panel-header">
      <p>Serving Size: <b>{{servingSize.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="servingSize" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="servingSize.id">
              <div class="box-header"><p>Markets</p></div>
              <TagList :tags="servingSize.markets" tag-key="market_id" :all-tags="allMarkets" prop="name" secondary="amount_g" secondary-suffix="g" @remove-tag="onRemoveMarket" />
              <AddMarketForm :markets="allMarkets" @add="onAddMarket" />
            </div>
            <div class="box" v-if="servingSize.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" secondary="name_plural" :on-submit="setLocale" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ servingSize.id?'Update Serving Size':'Create Serving Size' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import servingSizeFields from "@/lib/form-fields/servingSize";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import AddMarketForm from "@/components/forms/AddMarketForm";
import TagList from "@/components/ui/TagList";
import LocaleForm from "@/components/forms/LocaleForm";

const localeFields = [
  {name: "name", type:"text", label:"Name"},
  {name: "name_plural", type:"text", label:"Plural Form"},
]


export default {

  components: {LocaleForm, TagList, AddMarketForm, AppForm },

  data() {
    return {
      fields : servingSizeFields,
      localeFields,
      servingSize : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "ServingSize",
  computed: {
    ...mapGetters(['isLoggedIn', 'allServingSizes', 'allServingSizes','allServingSizes','allLocales', 'allMarkets','savedServingSize']),
  },
  methods: {
    ...mapActions(['getServingSizes','getServingSizes','setLoading','setSuccess','getServingSizes','getLocales','getMarkets','updateServingSize','createServingSize']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.servingSize.id) { //update
        await this.updateServingSize([this.servingSize, this.servingSize.id])
      }
      else { // create new
        await this.createServingSize(this.servingSize);
        if (this.savedServingSize.id) {
          await this.$router.push('/serving-size/'+this.savedServingSize.id)
          this.servingSize = {...this.savedServingSize }
          await this.initData()
        }
      }
      await this.setLoading(false);
    },

    onUpdate(values) {
      this.servingSize = {...this.servingSize, ...values};
    },

    async initData() {
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allMarkets.length <= 0) await this.getMarkets();
      if (this.allLocales.length <= 0) await this.getLocales();


      this.servingSize = !isNaN(this.$route.params.id*1) ? this.allServingSizes.find(it => it.id === this.$route.params.id*1) :
          {servingSize: "new-servingSize"}

      if (this.servingSize.id) await this.getServingSizeLocales()
    },

    async getServingSizeLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetServingSizeLocales, this.servingSize.id))
    },

    async createServingSizeLocale(serving_size_id, locale_id, name, name_plural) {
      return await ApiRequest(ApiCall(Api.CreateServingSizeLocale, { locale_id, name, name_plural },  serving_size_id))
    },
    async updateServingSizeLocale(serving_size_id, locale_id, name, name_plural) {
      return await ApiRequest(ApiCall(Api.UpdateServingSizeLocale, { name, name_plural },  serving_size_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, name_plural, id = null}, locale_id) {
      if (id) {
        await this.updateServingSizeLocale(this.servingSize.id, locale_id, name, name_plural)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, name_plural} : it)
      }
      else {
        const res = await this.createServingSizeLocale(this.servingSize.id, locale_id, name, name_plural)
        this.locales.push({id: res.insertId, locale_id, name, name_plural})
      }
      this.settingLocale = null
    },

    onAddMarket(market_id, amount_g) {
      this.servingSize.markets.push({serving_size_id: this.servingSize.id, market_id, amount_g })
    },

    onRemoveMarket(id) {
      this.servingSize.markets = this.servingSize.markets.filter(it => it.id !== id)
    },
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
