import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        userPermissions : []
    },
    getters : {
        allUserPermissions: state => state.userPermissions
    },
    actions : {
        async getUserPermissions({commit}) {
            const permissions = await ApiRequest(ApiCall(Api.ListUserPermissions));
            commit('setUserPermissions', permissions);
        },
    },
    mutations : {
        setUserPermissions: (state, permissions) => state.userPermissions = permissions,
    }
}
