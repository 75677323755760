<template>
  <div class="panel inline">
    <div class="modal" v-if="prefGroupForDeletion || repGroupForDeletion">
      <div class="modal-inner">
        <h3>Are you sure?</h3>
        <p>Do you really want to delete the group <b>{{prefGroupForDeletion? prefGroupForDeletion.name : repGroupForDeletion.name}}</b>?</p>
        <div class="button-row even">
          <div class="diagonal-wrap">
            <button @click="confirmDeleteGroup">Delete Group</button>
          </div>
          <button class="unwrap" @click="prefGroupForDeletion = repGroupForDeletion = null">Cancel</button>
        </div>
      </div>
    </div>
    <div class="even packed">
      <div>
        <div class="panel-header">
          <p>Preference Groups</p>
          <div class="search-box">
            <router-link to="/pref-group/new" class="diagonal-wrap">
              <button>Add Preference Group</button>
            </router-link>
            <div class="diagonal-wrap">
              <input type="text" placeholder="Search Groups" v-model="prefSearch" />
            </div>
            <div class="close-btn" v-if="prefSearch" @click="() => this.prefSearch = ''"></div>
          </div>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <app-list :data="filteredPrefGroups" :fields="prefFields" :on-click="gotoPref" @delete="askToDeletePrefGroup" />
          </div>
        </div>
      </div>
      <div>
        <div class="panel-header">
          <p>Replacement Groups</p>
          <div class="search-box">
            <router-link to="/rep-group/new" class="diagonal-wrap">
              <button>Add Replacement Group</button>
            </router-link>
            <div class="diagonal-wrap">
              <input type="text" placeholder="Search Groups" v-model="repSearch" />
            </div>
            <div class="close-btn" v-if="repSearch" @click="() => this.repSearch = ''"></div>
          </div>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <app-list :data="filteredRepGroups" :fields="repFields" :on-click="gotoRep" @delete="askToDeleteRepGroup" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AppList from '../../ui/AppList';
import {delayedIf} from "@/lib/Async";

export default {
  name: "Groups",

  data() {
    return {
      prefGroupForDeletion: null,
      repGroupForDeletion: null,
      prefSearch : "",
      repSearch : "",
      prefFields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Ingredients", prop: "id", width: "20%", primary: true, format: (id) => this.ingredientsInPrefGroup(id) },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ],
      repFields : [
        {label: "Name", prop: "name", primary: true},
        {label: "Ingredients", prop: "id", width: "20%", primary: true, format: (id) => this.ingredientsInRepGroup(id) },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allRepGroups', 'allPrefGroups','allPlanIngredients']),
    filteredPrefGroups() { return this.prefSearch.length >= 1 ? this.allPrefGroups.filter(it => it.name.toLowerCase().indexOf(this.prefSearch.toLowerCase()) > -1) : this.allPrefGroups },
    filteredRepGroups() { return this.repSearch.length >= 1 ? this.allRepGroups.filter(it => it.name.toLowerCase().indexOf(this.repSearch.toLowerCase()) > -1) : this.allRepGroups }
  },

  methods: {
    ...mapActions(['getRepGroups','getPrefGroups','getPlanIngredients','setLoading','deleteRepGroup','deletePrefGroup']),
    gotoPref(group) {
      this.$router.push('/pref-group/'+group.id)
    },
    gotoRep(group) {
      this.$router.push('/rep-group/'+group.id)
    },
    askToDeletePrefGroup(group) {
      this.prefGroupForDeletion = group;
    },
    askToDeleteRepGroup(group) {
      this.repGroupForDeletion = group;
    },
    confirmDeleteGroup() {
      if (this.prefGroupForDeletion)
        this.deletePrefGroup(this.prefGroupForDeletion.id)
      if (this.repGroupForDeletion)
        this.deleteRepGroup(this.repGroupForDeletion.id)
      this.repGroupForDeletion = this.prefGroupForDeletion = null
    },
    ingredientsInPrefGroup(groupId) {
      return this.allPlanIngredients.filter(it => it.pref_group_id === groupId).length
    },
    ingredientsInRepGroup(groupId) {
      return this.allPlanIngredients.filter(it => it.rep_group_id === groupId).length
    }
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else {
        if (this.allPrefGroups.length <= 0) await this.getPrefGroups();
        if (this.allRepGroups.length <= 0) await this.getRepGroups();
        if (this.allPlanIngredients.length <= 0) await this.getPlanIngredients();
      }
      await this.setLoading(false)
    }, 800)
  },
  components: { AppList }
}
</script>
