<template>
  <div v-if="meal" class="panel inline">
    <div class="panel-header">
      <p>Plan Meal: <b>{{meal.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <AppForm :fields="fields" :data="planMeal" @update="onUpdate" />
            <MealRecipeForm :recipes="meal&&meal.recipes?meal.recipes:[]" :passive="true" :plan-ingredients="allPlanIngredients" />
            <MealIngredientForm :ingredients="meal&&meal.ingredients?meal.ingredients:[]" :passive="true" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="meal.id">
              <div class="box-header"><p>Locales</p></div>
              <div class="locales">
                <div class="locale" v-for="locale in allLocales" :key="locale.id">
                  <div v-if="hasLocale(locale)">
                    <h3><div :class="'btn ' + (locales?(hasLocale(locale)?'success-btn':'danger-btn'):'disabled-btn')"></div> {{locale.description}}</h3>
                    <div v-if="hasLocale(locale)" class="locale-inner">{{getLocale(locale).name}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="allPlanMealIngredients && allPlanMealIngredients.length">
              <NutritionCounter :ingredients="allPlanMealIngredients" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ meal.id?'Update Plan Meal':'Create Plan Meal' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import planMealFields from "@/lib/form-fields/planMeal";
import AppForm from "@/components/ui/AppForm";
import MealRecipeForm from "@/components/forms/MealRecipeForm";
import MealIngredientForm from "@/components/forms/MealIngredientForm";
import NutritionCounter from "@/components/ui/NutritionCounter";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {delayedIf} from "@/lib/Async";

export default {

  components: { AppForm, MealRecipeForm, MealIngredientForm, NutritionCounter },

  data() {
    return {
      fields : planMealFields,
      planMeal : null,
      meal : null,
      locales : null,
    }
  },

  name: "PlanMeal",
  computed: {
    ...mapGetters(['isLoggedIn', 'allMeals', 'allPlanIngredients', 'allPlanMeals', 'allTags','allServingSizes','allLocales','allRecipes']),
    allPlanMealIngredients() {
      const ingredients = this.meal && this.meal.ingredients ? [...this.meal.ingredients] : []
      if (this.meal.recipes) {
        for (let recipe of this.meal.recipes) {
          if (recipe.recipe_id) {
            const rec = this.allRecipes.find(it => it.id === recipe.recipe_id);
            if (rec) {
              for (let ingredient of rec.ingredients) ingredients.push(ingredient)
            }
          }
        }
      }
      return ingredients
    }
  },
  methods: {
    ...mapActions(['getPlanMeals', 'getPlanIngredients', 'getMeals', 'getTags', 'getRecipes', 'setLoading', 'setSuccess', 'getServingSizes', 'getLocales', 'updatePlanMeal', 'createPlanMeal']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.planMeal.id) { //update
        await this.updatePlanMeal([this.planMeal, this.planMeal.id])
      } else { // create new
        await this.createPlanMeal(this.planMeal);
        if (this.planMeal.id) {
          await this.$router.push('/plan-meal/' + this.planMeal.id)
          await this.initData()
          this.planMeal = {...this.planMeal}
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Plan Meal saved");
    },

    onUpdate(values) {
      this.planMeal = {...this.planMeal, ...values};
    },

    async initData() {
      if (this.allMeals.length <= 0) await this.getMeals();
      if (this.allPlanMeals.length <= 0) await this.getPlanMeals();
      if (this.allPlanIngredients.length <= 0) await this.getPlanIngredients();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allRecipes.length <= 0) await this.getRecipes();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.planMeal = !isNaN(this.$route.params.id * 1) ? this.allPlanMeals.find(it => it.id === this.$route.params.id * 1) : null
      this.meal = this.planMeal && this.allMeals ? this.allMeals.find(it => it.id === this.planMeal.meal_id) : {ingredients:[]}

      if (this.meal && this.meal.id) await this.getPlanMealLocales()
    },

    async getPlanMealLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetMealLocales, this.meal.id))
    },


    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else await this.initData()
      await this.setLoading(false)
    }, 800)
  },


}
</script>
