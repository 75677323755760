const getRuleProp = (type, val, source, params) => {
    switch (type) {
        case 'value': return val;
        case 'param': return params[val];
        case 'source': return source[val];
    }
}

export default {
    BucketCompare : (position, filters, accumulated, fetch, params, buckets) => {
        if (filters.length === 0) return true;
        const nuts = []
        if (position.hash.length > position.toIndex) return false;
        for (let p = 0; p < position.hash.length; p++) {
            if (!buckets[p].inactive) nuts.push(fetch(p, position.hash[p]))
        }
        nuts.push(fetch(position.toIndex, position.toKey))

        for (let f of filters) {
            if (nuts.length-1 < f[0] || nuts.length-1 < f[1]) continue; // data for rule still isn't in tree
            switch (f[2]) { // switch operator
                case '>': if (! (nuts[f[0]][f[3]] > nuts[f[1]][f[3]])) return false; break;
                case '>=': if (! (nuts[f[0]][f[3]] >= nuts[f[1]][f[3]])) return false; break;
                case '=': case '==': if (! (nuts[f[0]][f[3]] === nuts[f[1]][f[3]])) return false; break;
                case '<=': if (! (nuts[f[0]][f[3]] <= nuts[f[1]][f[3]])) return false; break;
                case '<': if (! (nuts[f[0]][f[3]] < nuts[f[1]][f[3]])) return false; break;
                case '!=': if (! (nuts[f[0]][f[3]] !== nuts[f[1]][f[3]])) return false; break;
            }
        }
        return true
    },
    Boolean : (position, filters, accumulated, fetch, params) => {
        if (filters.length === 0) return true;
        let f, cond;
        let passed = false, failed = false;
        for (f = 0; f < filters.length; f++) {
            failed = false;
            for (cond = 0; cond < filters[f].length; cond++) {

                const one = getRuleProp(filters[f][cond][0], filters[f][cond][1], filters[f][cond][0]==='source'?fetch(position.toIndex, position.toKey):null, params),
                    two = getRuleProp(filters[f][cond][3], filters[f][cond][4], filters[f][cond][3]==='source'?fetch(position.toIndex, position.toKey):null, params);

                switch(filters[f][cond][2]) {
                    case '>': if (! (one > two)) failed = true; break
                    case '>=': if (! (one >= two)) failed = true; break
                    case '=': case '==': if (! (one === two)) failed = true; break
                    case '<=': if (! (one <= two)) failed = true; break
                    case '<': if (! (one < two)) failed = true; break
                    case '!=': if (! (one !== two)) failed = true; break
                    case 'IN': if (! (two instanceof Array && two.map(it => it.indexOf(one) > -1).indexOf(true) > -1)) failed = true; break
                    case 'NOT_IN': if (! (two instanceof Array && two.map(it => it.indexOf(one) > -1).indexOf(true) === -1)) failed = true; break
                }
            }
            if (!failed) return true;
        }
        return passed;
    },
    Range : (position, filters, accumulated, fetch, params) => {
        if (!position.last || filters.length === 0) return true;
        const key = position.hash + (position.toKey+''), accu = accumulated[key] || {};

        for (let f of filters) {
            const one = getRuleProp(f[0], f[1], f[0]==='source'?accu:null, params),
                two = getRuleProp(f[2], f[3], f[2]==='source'?accu:null, params),
                min = f[4], max = f[5];

            if (one < two * min || one > two * max) return false
        }

        return true;
    }
}
