export function delayedIf(condition, callback, timeout) {
    return new Promise((success) => {
        if (condition) {
            setTimeout(() => {
                callback()
                success()
            }, timeout)
        }
        else {
            callback()
            success()
        }

    })
}
