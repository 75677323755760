<template>
  <div class="panel inline">
    <div class="panel-body sides">
      <div class="side first">
        <div class="panel-header">
          <p>Nutrition Settings</p>
        </div>
        <div class="panel-body">
          <div class="full-scroller in-panel">
            <NutritionPanel v-if="myPlan" :inputs="myPlan.inputs" :data="inputs" :on-change="onNutritionChange" />
            <RulesSwitcher v-if="myPlan" :rules="myPlan.rules" @change="onRuleToggle" />
            <SortingPanel :on-change="onSortingChange" :data="myPlan?myPlan.sorting:{}" />
            <PreferencesPanel :on-change="onMenuPreferences" />
            <BucketSwitcher @reset="runTree" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="panel-header">
          <p>Menu Result</p>
        </div>
        <div class="panel-bod">
          <div class="full-scroller in-panel">
            <MealsMenu :meals="meals" :no-results="noResults" :meal-alternatives="mealAlternatives" :meal-alternatives-goals="mealAlternativesGoals" :replace-meal="replaceMeal" />
            <NutritionResults :nutrition="nutrition" :calories="planSortBy('calories')" :protein="planSortBy('protein')" :fat="planSortBy('fat')" />
            <PerformanceResults :meta="meta" :calories="planSortBy('calories')" :protein="planSortBy('protein')" :fat="planSortBy('fat')" :nutrition="nutrition" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NutritionUtils, {
  applyPreferencesToMeals,
  closestMealIndex,
  getAvailableMeals,
  getDailyNutrition,
  mealNutrition,
  mealsNutrition,
  menuMealReplacements,
} from '@/lib/NutritionUtils';
import Single from "@/lib/Single";
import NutritionPanel from "@/components/menu/NutritionPanel";
import PreferencesPanel from "@/components/menu/PreferencesPanel";
import SortingPanel from "@/components/menu/SortingPanel";
import MealsMenu from "@/components/menu/MealsMenu";
import NutritionResults from "@/components/menu/NutritionResults";
import PerformanceResults from "@/components/menu/PerformanceResults";
import {mapActions, mapGetters} from "vuex";
import NuTree from "@/lib/nutree/NuTree";
import Config from "@/lib/nutree/Config";
import RulesSwitcher from "@/components/menu/RulesSwitcher";
import BucketSwitcher from "@/components/menu/BucketSwitcher";
import {delayedIf} from "@/lib/Async";


export default {
  name: "MenuBuilder",

  components : {
    BucketSwitcher,
    RulesSwitcher, MealsMenu, NutritionPanel, PreferencesPanel, NutritionResults, PerformanceResults, SortingPanel },

  data : function() {
    return {
      inputs : {
        calories: 1550,
        weight: 55,
      },
      meals : null,
      nutrition: null,
      noResults : false,
      mealAlternatives: null,
      mealAlternativesGoals : null,
      meta : null,
      tree : null,
      options: Config,

      sorting : null,

      mealsDb : [],
    }
  },

  computed: {
    ...mapGetters(['allPlanMeals', 'allPlanIngredients', 'allPrefGroups', 'allIngredients', 'allMeals', 'allMealTypes', 'myPlan', 'allPlanMealTypes', 'allPlanMealStyles', 'allMealTypes', 'currentPlan','isLoggedIn']),
    mainPlanMeals() {
      let meals = this.allPlanMeals, meal
      if (this.allPlanMealStyles && this.allMeals) {
        let mainStyles = this.allPlanMealStyles.filter(it => it.is_main === true)
        mainStyles = mainStyles.map(it => it.id)
        meals = meals.filter(it => {
          meal = this.allMeals.find(m => m.id === it.meal_id)
          if (!meal) return true
          return !meal.meal_style_id || mainStyles.indexOf(meal.meal_style_id) > -1
        })
      }
      return meals;
    },

    fullPlanMeals() {
      let meals = this.allPlanMeals
      if (this.allPlanMeals && this.allMeals) {
        meals = meals.map(it => ({...it, meal: this.allMeals.find(m => m.id === it.meal_id)}))
      }
      return meals
    },
  },

  methods : {
    ...mapActions(['getPlanMeals', 'getPlanIngredients','getPrefGroups','getIngredients','getMeals','getMealTypes','getMyPlan','getPlanMealTypes', 'getPlanMealStyles', 'getMealTypes', 'setLoading']),

    planSorting() {
      let sort = {};
      if (this.myPlan.sorting) {
        if (this.myPlan.sorting.calories) { sort.calories = this.inputs[this.myPlan.sorting.calories.input] * this.myPlan.sorting.calories.factor }
        if (this.myPlan.sorting.protein) { sort.protein = this.inputs[this.myPlan.sorting.protein.input] * this.myPlan.sorting.protein.factor }
        if (this.myPlan.sorting.fat) { sort.fat = this.inputs[this.myPlan.sorting.fat.input] * this.myPlan.sorting.fat.factor }
        sort.powerCal = this.myPlan.sorting.powerCal || 1
        sort.powerProtein = this.myPlan.sorting.powerProtein || 1
        sort.powerFat = this.myPlan.sorting.powerFat || 1
      }
      return sort;
    },

    planSortBy(prop) {

      if (!this.myPlan || !this.myPlan.sorting) return 0;
      switch (prop) {
        case 'calories': return this.inputs[this.myPlan.sorting.calories.input] * this.myPlan.sorting.calories.factor;
        case 'protein': return this.inputs[this.myPlan.sorting.protein.input] * this.myPlan.sorting.protein.factor;
        case 'fat': return this.inputs[this.myPlan.sorting.fat.input] * this.myPlan.sorting.fat.factor;

      }
    },

    replaceMeal(index, newMeal) {
      const oldMeal = {...this.meals[index]}
      this.$set(this.meals, index, newMeal)

      const repIndex = this.mealAlternatives[index].findIndex(it => it.id === newMeal.id)
      this.mealAlternatives[index].splice(repIndex, 1, oldMeal)

      this.nutrition = mealsNutrition(this.meals);
    },

    async runTree(reset = false, data = null) {
      await this.setLoading(true);
      setTimeout(async () => {
        const tree = Single.get('tree');
        const time = new Date().getTime();

        if (reset) {
          if (data) tree.options.data = data;
          tree.reset();
        }

        let res = tree.run({}, this.inputs);

        // resolve sort factors
        const sort = this.planSorting()
        if (!this.sorting) {
          this.sorting = {
            powerCal : sort.powerCal || 1,
            powerProtein : sort.powerProtein || 1,
            powerFat : sort.powerFat || 1,
          }
        }

        res = tree.sortResults(res, {
              calories: sort.calories, protein: sort.protein, fat: sort.fat,
              powerCal: this.sorting.powerCal, powerProtein: this.sorting.powerProtein, powerFat: this.sorting.powerFat
            }
        );

        if (res.length === 0) {
          this.noResults = true;
        }
        else {
          this.noResults = false;
          const mealData = getAvailableMeals(tree.buckets)
          this.meals = tree.extractResult(mealData, res[0]);
          this.nutrition = getDailyNutrition(mealData, res[0]);
        }

        let replacements = this.meals ? menuMealReplacements(this.allPlanMealStyles, this.meals, this.fullPlanMeals, this.myPlan.meal_rep_offsets) : null

        // limit alternative groups
        const replacementGroups = {}
        for (let r = 0; r < replacements.length; r++) {
          for (let s = 0; s < replacements[r].length;s++) {
            if (replacements[r][s].meal.rep_group) {
              if (!replacementGroups[r]) replacementGroups[r] = {};
              if (!replacementGroups[r][replacements[r][s].meal.rep_group]) replacementGroups[r][replacements[r][s].meal.rep_group] = []
              replacementGroups[r][replacements[r][s].meal.rep_group].push(s)
            }
          }
        }

        let candidates, closest, nut;

        // replacement group filtering
        if (Object.keys(replacementGroups).length > 0) {
          for (let r in replacementGroups) {
            candidates = []
            for (let grp in replacementGroups[r]) {
              if (replacementGroups[r][grp].length <= 1) continue;
              replacementGroups[r][grp].forEach(index => {
                candidates.push(replacements[r][index])
              })
              nut = mealNutrition(this.meals[r])
              closest = closestMealIndex(candidates, nut.calories, nut.protein_g, nut.fat_g)
              let added = false
              for (let gi = replacementGroups[r][grp].length-1; gi >= 0; gi--) {
                if (added) {
                  replacements[r].splice(replacementGroups[r][grp][gi], 1)
                }
                else {
                  replacements[r].splice(replacementGroups[r][grp][gi], 1, candidates[closest])
                  added = true
                }
              }
            }
          }
        }

        this.mealAlternativesGoals = this.meals.map(it => {
          const n = mealNutrition(it)
          return { calories: n.calories, protein_g: n.protein_g, fat_g: n.fat_g }
        })

        this.mealAlternatives = replacements;

        this.meta = {
          time: new Date().getTime() - time,
          initial: tree.seeds,
          valid: res.length,
          results: tree.getData(res, 'nutrition'),
        }
        await this.setLoading(false)
      }, 10)
    },

    onNutritionChange(prop, val) {
      switch (prop) {
        case 'calories': this.inputs.calories = val*1; break;
        case 'weight': this.inputs.weight = val*1; break;
      }

      this.runTree()
    },

    onSortingChange(prop, val) {
      switch (prop) {
        case 'powerCal': this.$set(this.sorting, "powerCal", val*1); break;
        case 'powerProtein': this.$set(this.sorting, "powerProtein", val*1); break;
        case 'powerFat': this.$set(this.sorting, "powerFat", val*1); break;
      }
      this.runTree()
    },

    onMenuPreferences(pref) {
      this.mealsDb = this.mainPlanMeals && this.allPrefGroups ? applyPreferencesToMeals([...this.mainPlanMeals], pref, this.allPrefGroups) : [];

      this.runTree(true, this.mealsDb)
    },

    async onRuleToggle(index) {
        if (this.myPlan.rules[index].inactive) delete this.myPlan.rules[index].inactive
        else this.myPlan.rules[index].inactive = true

      const planMealTypes = this.allPlanMealTypes.map(it => this.allMealTypes.find(mt => mt.id === it))

      let tree = new NuTree([], {
          data : this.mealsDb,
          designator: this.options.designator,
          buckets : planMealTypes,
          sources : this.options.sources,
          rules : this.myPlan.rules,
          sorting: this.options.sorting,
        });
        Single.set('tree', tree);

        this.runTree()
    }
  },

  async mounted() {

    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.myPlan) await this.getMyPlan()
      if (this.allMeals.length <= 0) await this.getMeals();
      if (this.allPrefGroups.length <= 0) await this.getPrefGroups();
      if (this.allPlanMeals.length <= 0) await this.getPlanMeals();
      if (this.allPlanIngredients.length <= 0) await this.getPlanIngredients();
      if (this.allMealTypes.length <= 0) await this.getMealTypes();
      if (this.allPlanMealTypes.length <= 0) await this.getPlanMealTypes();
      if (this.allPlanMealStyles.length <= 0) await this.getPlanMealStyles();
      await this.setLoading(false)

      NutritionUtils.setIngredientsDb([...this.allPlanIngredients])
      this.mealsDb = [...this.mainPlanMeals]

      const planMealTypes = this.allPlanMealTypes.map(it => this.allMealTypes.find(mt => mt.id === it))

      let tree = new NuTree([], {
        data : this.mealsDb,
        designator: this.options.designator,
        buckets : planMealTypes,
        sources : this.options.sources,
        rules : this.myPlan.rules,
        sorting: this.options.sorting,
      });
      Single.set('tree', tree);

      setTimeout(() => {
        this.runTree()
      },1)

    }, 800)

  },

}

</script>
