<template>
  <div v-if="prefGroup" class="panel inline">
    <div class="panel-header">
      <p>Preference Group: <b>{{prefGroup.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="prefGroup" @update="onUpdate" />
            <GroupIngredientForm v-if="prefGroup && prefGroup.id" :ingredients="ingredients" @add="onAddIngredient" @delete="onDeleteIngredient" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ prefGroup.id?'Update Preference Group':'Create Preference Group' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import prefGroupFields from "@/lib/form-fields/prefGroup";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import GroupIngredientForm from "@/components/forms/GroupIngredientForm";
import {delayedIf} from "@/lib/Async";

export default {

  components: {GroupIngredientForm, AppForm, },

  data() {
    return {
      fields : prefGroupFields,
      prefGroup : null,
      ingredients: [],
    }
  },

  name: "PrefGroup",
  computed: {
    ...mapGetters(['isLoggedIn', 'allPrefGroups']),
  },
  methods: {
    ...mapActions(['getPrefGroups', 'getPlanIngredients', 'setLoading', 'setSuccess', 'updatePrefGroup', 'createPrefGroup']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.prefGroup.id) { //update
        await this.updatePrefGroup([this.prefGroup, this.prefGroup.id])
      } else { // create new
        await this.createPrefGroup(this.prefGroup);
        if (this.prefGroup.id) {
          await this.$router.push('/pref-group/' + this.prefGroup.id)
          await this.initData()
          this.prefGroup = {...this.prefGroup}
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Preference Group saved");
    },

    onUpdate(values) {
      this.prefGroup = {...this.prefGroup, ...values};
    },

    async onAddIngredient(ingredient) {
      await ApiRequest(ApiCall(Api.UpdatePlanIngredient, {...ingredient, pref_group_id: this.prefGroup.id}, ingredient.id))
      this.ingredients.push(ingredient)

      await this.getPlanIngredients()
    },

    async onDeleteIngredient(ingredient) {
      await ApiRequest(ApiCall(Api.UpdatePlanIngredient, {pref_group_id: null}, ingredient.id));
      this.ingredients = this.ingredients.filter(it => it.id !== ingredient.id)
      await this.getPlanIngredients()

    },

    async initData() {
      if (this.allPrefGroups.length <= 0) await this.getPrefGroups();
      this.prefGroup = !isNaN(this.$route.params.id * 1) ? this.allPrefGroups.find(it => it.id === this.$route.params.id * 1) : {ingredients:[]}

      this.ingredients = await ApiRequest(ApiCall(Api.GetPrefGroupIngredients, this.$route.params.id))
    },
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else await this.initData()
      await this.setLoading(false)
    },800)
  },


}
</script>
