<template>
  <div v-if="ingredient" class="panel inline">
    <div class="panel-header">
      <p>Plan Ingredient: <b>{{ingredient.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller form-padded in-panel">
            <app-form :fields="fields" :data="planIngredient" @update="onUpdate" />
            <div class="padded even">
              <div>
                <h2 class="glow-text">Icon</h2>
                <div class="form-group">
                  <div class="form-padded">
                    <IconPicker :icons="allPlanIngredientIcons" :current="planIngredient.icon_id" @select="selectIcon" />
                  </div>
                </div>
              </div>
              <div>
                <h2 class="glow-text">Groups</h2>
                <div class="form-group">
                  <div class="even form-padded">
                    <div>
                      <p>Preference Group</p>
                        <v-select v-model="planIngredient.pref_group_id" :options="allPrefGroups" label="name" :reduce="it => it.id" placeholder="Select Preference Group" />
                    </div>
                    <div>
                      <p>Replacement Group</p>
                        <v-select v-model="planIngredient.rep_group_id" :options="allRepGroups" label="name" :reduce="it => it.id" placeholder="Select Replacement Group" />
                    </div>
                    <div v-if="planIngredient.rep_group_id">
                      <p>Sub-Group</p>
                      <input type="text" v-model="planIngredient.sub_group" placeholder="Sub-Group (optional)" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NutritionCounter :ingredients="ingredients" label="Nutrients for 100g" />
            <div class="form-group" v-if="meals.length > 0">
              <div class="form-group-content">
                <h3><span class="glow-text">Exists in Meals:</span></h3>
                <div class="link-list">
                  <router-link v-for="meal in meals" :key="meal.id" :to="'/plan-meal/'+meal.id">{{meal.name}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Tags</p></div>
              <tag-list :all-tags="allTags" tag-key="tag_id" :tags="ingredient.tags" :passive="true" prop="tag" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Serving Sizes</p></div>
              <tag-list :all-tags="allServingSizes" tag-key="serving_size_id" :passive="true"
                        :tags="ingredient.serving_sizes" prop="name" secondary="amount_g" secondary-suffix="g" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Markets</p></div>
              <tag-list :all-tags="allMarkets" tag-key="market_id" :tags="ingredient.markets" :passive="true" prop="name" />
            </div>
            <div class="box" v-if="ingredient.id">
              <div class="box-header"><p>Locales</p></div>
              <div class="locales">
                <div class="locale" v-for="locale in allLocales" :key="locale.id">
                  <h3><div :class="'btn ' + (locales?(hasLocale(locale)?'success-btn':'danger-btn'):'disabled-btn')"></div> {{locale.description}}</h3>
                  <div v-if="hasLocale(locale)" class="locale-inner">{{getLocale(locale).name}}</div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ ingredient.id?'Update Plan Ingredient':'Create Plan Ingredient' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import planIngredientFields from "@/lib/form-fields/planIngredient";
import AppForm from "@/components/ui/AppForm";
import TagList from "@/components/ui/TagList";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import NutritionCounter from "@/components/ui/NutritionCounter";
import IconPicker from "@/components/ui/IconPicker";
import {delayedIf} from "@/lib/Async";

export default {

  components: {IconPicker, NutritionCounter, AppForm, TagList, },

  data() {
    return {
      fields : planIngredientFields,
      ingredient : null,
      planIngredient : null,
      locales : null,
    }
  },

  name: "PlanIngredient",
  computed: {
    ...mapGetters(['isLoggedIn', 'allIngredients', 'allPlanMeals', 'allPlanIngredientIcons', 'allPrefGroups','allRepGroups', 'allPlanIngredients', 'allTags','allServingSizes','allMarkets','allLocales']),
    ingredients() { return [{...this.planIngredient, ingredient: this.ingredient, amount_g: 100, serving_amount: 1}] },
    meals() {
      return this.planIngredient && this.allPlanMeals ?
          this.allPlanMeals.filter(it => it.ingredients ? it.ingredients.find(ing => ing.meal_id === it.meal_id && (ing.ingredient_id === this.planIngredient.ingredient_id || (ing.ingredient && ing.ingredient.id === this.planIngredient.ingredient_id))):null)
          : []
    }
  },
  methods: {
    ...mapActions(['getPlanIngredients','getIngredients', 'getPlanMeals', 'getPlanIngredientIcons', 'getPrefGroups','getRepGroups', 'getTags','setLoading','setSuccess','getServingSizes','getMarkets','getLocales','updatePlanIngredient','createPlanIngredient','createServingSize']),

    async onSubmit() {
      await this.setLoading(true);

      // update
      if (this.planIngredient.id) {
        const planIngredient = {...this.planIngredient, rep_group_id : this.planIngredient.rep_group_id?this.planIngredient.rep_group_id: null,
          pref_group_id: this.planIngredient.pref_group_id?this.planIngredient.pref_group_id : null}
        await this.updatePlanIngredient([planIngredient, this.planIngredient.id])
      }

      // create new
      else {
        await this.createPlanIngredient(this.planIngredient); // this.ingredient.id is created here
        if (this.planIngredient.id) {
          await this.$router.push('/plan-ingredient/'+this.planIngredient.id)
          await this.initData()
          this.planIngredient = {...this.planIngredient }
        }
      }

      await this.setLoading(false);
      await this.setSuccess("Plan Ingredient saved");
    },

    onUpdate(values) {
      this.planIngredient = {...this.planIngredient, ...values};
    },

    onTags(tags) {
      this.ingredient.tags = tags;
    },

    onServingSizes(servingSizes) {
      this.ingredient.serving_sizes = servingSizes;
    },

    async initData() {
      if (this.allIngredients.length <= 0) await this.getIngredients();
      if (this.allPlanIngredientIcons.length <= 0) await this.getPlanIngredientIcons();
      if (this.allPlanIngredients.length <= 0) await this.getPlanIngredients();
      if (this.allPlanMeals.length <= 0) await this.getPlanMeals();
      if (this.allPrefGroups.length <= 0) await this.getPrefGroups();
      if (this.allRepGroups.length <= 0) await this.getRepGroups();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allMarkets.length <= 0) await this.getMarkets();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.planIngredient = !isNaN(this.$route.params.id * 1) ? this.allPlanIngredients.find(it => it.id === this.$route.params.id * 1) : null
      this.ingredient = this.planIngredient && this.allIngredients ? this.allIngredients.find(it => it.id === this.planIngredient.ingredient_id) :
          {name: "", tags:[], serving_sizes:[], markets: []}

      // if (!this.planIngredient.pref_group_id) this.planIngredient.pref_group_id = ''
      // if (!this.planIngredient.rep_group_id) this.planIngredient.rep_group_id = ''

      if (this.ingredient && this.ingredient.id) await this.getIngredientLocales()
    },

    async getIngredientLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetIngredientLocales, this.ingredient.id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    selectIcon(icon) {
      this.$set(this.planIngredient,'icon_id', icon.id);
    }
  },


  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else await this.initData()
      await this.setLoading(false)
    }, 800)
  },


}
</script>
