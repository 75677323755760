<template>
    <div class="panel inline">
      <ConfirmModal v-if="ingredientForDeletion" item-type="Ingredient" :item-name="ingredientForDeletion.name" :confirm="confirmDeleteIngredient" :cancel="cancelDeleteIngredient" />
      <ListPanel title="Ingredients" :fields="fields" :items="allIngredients" :on-add="addNewIngredient" :on-delete="askToDeleteIngredient" :on-row="goto" export-file="ingredients.csv" />
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import ListPanel from "@/components/ui/ListPanel";
import ConfirmModal from "@/components/ui/ConfirmModal";

export default {
  name: "Ingredients",

  components: {ConfirmModal, ListPanel },

  data() {
    return {
      ingredientForDeletion: null,
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true },
        {label: "Slug", prop: "slug", sortable: true, width: "10%" },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allIngredients']),
  },

  methods: {
    ...mapActions(['getIngredients','setLoading','deleteIngredient']),
    goto(ingredient) {
      this.$router.push('/ingredient/'+ingredient.id)
    },
    addNewIngredient() {
      this.$router.push('/ingredient/new')
    },
    askToDeleteIngredient(ingredient) {
      this.ingredientForDeletion = ingredient;
    },
    cancelDeleteIngredient() {
      this.ingredientForDeletion = null
    },
    confirmDeleteIngredient() {
      this.deleteIngredient(this.ingredientForDeletion.id)
      this.ingredientForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allIngredients.length <= 0) await this.getIngredients();
      },800)
    }
    else if (this.allIngredients.length <= 0) await this.getIngredients();
  },

}
</script>
