import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {setToken} from "@/lib/Storage";

export default {
    state : {
        user : null,
        token: null,
        loginError: null,
        plans: null,
        isLoading: false,
        currentPlan: null,
        error: '',
        success : '',
    },
    getters : {
        isLoggedIn : (state) => !!state.user,
        loginError : state => state.loginError,
        appError : state => state.error,
        currentUser : state => state.user,
        currentPlan : state => state.currentPlan,
        // currentPlan : state => {
        //     return state.plans && state.user ?
        //         state.plans.find(it => it.id === state.user.plan_id) : null
        // },
        isLoading : state => state.isLoading,
        success : state => state.success
    },
    actions : {

        async login({commit, dispatch}, [username, password]) {
            commit('resetLoginErrors')
            const res = await ApiRequest(ApiCall(Api.Login, {username, password}));
            if (res.token) {
                setToken(res.token);
                commit('loginSuccess', {user: res.user, token: res.token, plan_id: res.user.plan_id })
                const isEditor = res.user.permissions.find(it => it.indexOf('EDITOR') > -1)
                if (isEditor) dispatch('getPlans');
            }
            else commit('loginError', res.error)
        },

        async logout({commit}) {
            await ApiRequest(ApiCall(Api.Logout));
            commit('logout');
        },

        async getPlans({commit}) {
            const plans = await ApiRequest(ApiCall(Api.ListPlans));
            commit('onPlans', plans)
        },

        async checkLogin({commit, dispatch}) {
            const res = await ApiRequest(ApiCall(Api.CheckLogin));
            if (res.user) {
                commit('loginSuccess', {user: res.user, token: null, plan_id: res.user.plan_id })
                const isAdmin = res.user.permissions.find(it => it.indexOf('ADMIN') > -1)
                if (isAdmin) dispatch('getPlans');
            }
        },

        async setCurrentPlan({commit, dispatch}, plan_id) {
            const res = await ApiRequest(ApiCall(Api.SetCurrentPlan, plan_id));
            if (res.result === "ok") {
                commit('setPlanMeals',[])
                commit('setPlanIngredients',[])
                commit('setPlanIngredientIcons',[])
                commit('setPlanInputs',[])
                commit('setPlanRules',[])
                commit('setPlanMealTypes',[])
                commit('setPlanMealStyles',[])
                commit('setPrefGroups',[])
                commit('setRepGroups',[])
                dispatch('getMyPlan')
                commit('setPlan', plan_id);
            }
        },

        async setLoading({commit}, isLoading) {
            if (isLoading) commit('loading')
            else commit('loaded')
        },

        async setSuccess({commit}, success) {
            commit('success', success)
            if (success) {
                setTimeout(() => commit('clearSuccess'), 3000)
            }
        },
        async setError({commit}, error) {
            commit('setError', error)
            if (error) {
                setTimeout(() => commit('clearError'), 5000)
            }
        }

    },
    mutations : {
        setPlan : (state, plan_id) =>  state.currentPlan = plan_id*1>0 ? plan_id : null ,
        resetLoginErrors: (state) => { state.loginError = null },
        loginSuccess: (state, {user, token, plan_id}) => {
            if (token) state.token = token
            state.user = user
            state.currentPlan = plan_id ? plan_id : null
        },
        loginError: (state, loginError) => {
            state.loginError = loginError;
            state.user = state.token = null;
        },
        setError: (state, error) => {
            console.log({error})
            state.error = error;
        },
        clearError: state => state.error = '',
        onPlans : (state, plans) => { state.plans = plans },
        logout : (state) => { state.user = state.token = state.plans = state.currentPlan = null; },
        loading: (state) => { state.isLoading = true; },
        loaded: (state) => { state.isLoading = false; },
        success: (state, success) => { state.success = success; },
        clearSuccess : state => state.success = '',
    }
}
