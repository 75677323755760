<template>
  <div v-if="mealStyle" class="panel inline">
    <div class="panel-header">
      <p>Meal Style: <b>{{mealStyle.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="mealStyle" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="mealStyle.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ mealStyle.id?'Update Meal Style':'Create Meal Style' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import mealStyleFields from "@/lib/form-fields/mealStyle";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";

const localeFields = [
  {name: "name", type:"text", label:"Name"},
  {name: "description", type:"textarea", label:"Description"},
]

export default {

  components: {LocaleForm, AppForm },

  data() {
    return {
      fields : mealStyleFields,
      localeFields,
      mealStyle : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "MealStyle",
  computed: {
    ...mapGetters(['isLoggedIn', 'allMealStyles', 'allTags','allServingSizes','allLocales']),
  },
  methods: {
    ...mapActions(['getMealStyles','getTags','setLoading','setSuccess','getServingSizes','getLocales','updateMealStyle','createMealStyle']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.mealStyle.id) { //update
        await this.updateMealStyle([this.mealStyle, this.mealStyle.id])
      }
      else { // create new
        await this.createMealStyle(this.mealStyle);
        if (this.mealStyle.id) {
          await this.$router.push('/meal-style/'+this.mealStyle.id)
          await this.initData()
          this.mealStyle = {...this.mealStyle }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Meal Style saved");
    },

    onUpdate(values) {
      this.mealStyle = {...this.mealStyle, ...values};
    },

    async initData() {
      if (this.allMealStyles.length <= 0) await this.getMealStyles();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.mealStyle = !isNaN(this.$route.params.id*1) ? this.allMealStyles.find(it => it.id === this.$route.params.id*1) :
          {name: "New Meal Style"}

      if (this.mealStyle.id) await this.getMealStyleLocales()
    },

    async getMealStyleLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetMealStyleLocales, this.mealStyle.id))
    },

    async createMealStyleLocale(mealStyle_id, locale_id, name) {
      return await ApiRequest(ApiCall(Api.CreateMealStyleLocale, { locale_id, name },  mealStyle_id))
    },
    async updateMealStyleLocale(mealStyle_id, locale_id, name) {
      return await ApiRequest(ApiCall(Api.UpdateMealStyleLocale, {name},  mealStyle_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, id = null}, locale_id) {
      if (id) {
        await this.updateMealStyleLocale(this.mealStyle.id, locale_id, name)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name} : it)
      }
      else {
        const res = await this.createMealStyleLocale(this.mealStyle.id, locale_id, name)
        this.locales.push({id: res.insertId, locale_id, name})
      }
      this.settingLocale = null
    }
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
