<template>
    <div class="panel inline">
      <ConfirmModal v-if="mealStyleForDeletion" item-type="Meal Style" :item-name="mealStyleForDeletion.name" :confirm="confirmDeleteMealStyle" :cancel="cancelDeleteMealStyle" />
      <ListPanel title="Meal Styles" :fields="fields" :items="allMealStyles" :on-add="addNewMealStyle" :on-delete="askToDeleteMealStyle" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "MealStyles",

  components: {ListPanel, ConfirmModal },

  data() {
    return {
      mealStyleForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: 'str', width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMealStyles']),
  },

  methods: {
    ...mapActions(['getMealStyles','setLoading','deleteMealStyle']),
    goto(mealStyle) {
      this.$router.push('/meal-style/'+mealStyle.id)
    },
    addNewMealStyle() {
      this.$router.push('/meal-style/new')
    },
    askToDeleteMealStyle(mealStyle) {
      this.mealStyleForDeletion = mealStyle;
    },
    cancelDeleteMealStyle() { this.mealStyleForDeletion = null },
    confirmDeleteMealStyle() {
      this.deleteMealStyle(this.mealStyleForDeletion.id)
      this.mealStyleForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allMealStyles.length <= 0) await this.getMealStyles();
      },800)
    }
    else if (this.allMealStyles.length <= 0) await this.getMealStyles();
  },
}
</script>
