<template>
  <div v-if="market" class="panel inline">
    <div class="panel-header">
      <p>Market: <b>{{market.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="market" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="market.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ market.id?'Update Market':'Create Market' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import marketFields from "@/lib/form-fields/market";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";

const localeFields = [
  {name: "name", type:"text", label:"Name"},
]

export default {

  components: {LocaleForm, AppForm },

  data() {
    return {
      fields : marketFields,
      localeFields,
      market : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "Market",
  computed: {
    ...mapGetters(['isLoggedIn', 'allMarkets', 'allTags','allServingSizes','allLocales']),
  },
  methods: {
    ...mapActions(['getMarkets','getTags','setLoading','setSuccess','getServingSizes','getLocales','updateMarket','createMarket']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.market.id) { //update
        await this.updateMarket([this.market, this.market.id])
      }
      else { // create new
        await this.createMarket(this.market);
        if (this.market.id) {
          await this.$router.push('/market/'+this.market.id)
          await this.initData()
          this.market = {...this.market }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Market saved");
    },

    onUpdate(values) {
      this.market = {...this.market, ...values};
    },

    async initData() {
      if (this.allMarkets.length <= 0) await this.getMarkets();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.market = !isNaN(this.$route.params.id*1) ? this.allMarkets.find(it => it.id === this.$route.params.id*1) :
          {name: "New Market"}

      if (this.market.id) await this.getMarketLocales()
    },

    async getMarketLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetMarketLocales, this.market.id))
    },

    async createMarketLocale(market_id, locale_id, name) {
      return await ApiRequest(ApiCall(Api.CreateMarketLocale, { locale_id, name },  market_id))
    },
    async updateMarketLocale(market_id, locale_id, name) {
      return await ApiRequest(ApiCall(Api.UpdateMarketLocale, {name},  market_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, id = null}, locale_id) {
      if (id) {
        await this.updateMarketLocale(this.market.id, locale_id, name)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name} : it)
      }
      else {
        const res = await this.createMarketLocale(this.market.id, locale_id, name)
        this.locales.push({id: res.insertId, locale_id, name})
      }
      this.settingLocale = null
    }
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
