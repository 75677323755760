<template>
  <div v-if="userRole" class="panel inline">
    <div class="panel-header">
      <p>User Role: <b>{{userRole.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="userRole" @update="onUpdate" />
            <div class="form-group">
            <div class="form-padded">
              <h3>Permissions</h3>
            </div>
              <div class="form-group-title">
              </div>
              <div class="form-group-content">
                <h3>Admin permissions</h3>
                <div class="permissions-list">
                  <div class="permission" v-for="(permission) in permissionGroups.admin" :key="permission">
                    <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
                    <p>{{permission}}</p>
                  </div>
                </div>
                <h3>Editor permissions</h3>
                <div class="permissions-list">
                  <div class="permission" v-for="(permission) in permissionGroups.editor" :key="permission">
                    <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
                    <p>{{permission}}</p>
                  </div>
                </div>
                <h3>User permissions</h3>
                <div class="permissions-list">
                  <div class="permission" v-for="(permission) in permissionGroups.user" :key="permission">
                    <input type="checkbox" :checked="hasPermission(permission)" @change="togglePermission(permission)">
                    <p>{{permission}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="userRole.id && userRole.ingredients && userRole.ingredients.length">
              <nutrition-counter :ingredients="userRole.ingredients" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ userRole.id?'Update User Role':'Create User Role' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import userRoleFields from "@/lib/form-fields/userRole";
import AppForm from "@/components/ui/AppForm";
import NutritionCounter from "@/components/ui/NutritionCounter";

export default {

  components: { AppForm, NutritionCounter },

  data() {
    return {
      fields : userRoleFields,
      userRole : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "UserRole",
  computed: {
    ...mapGetters(['isLoggedIn', 'allUserRoles', 'allUserPermissions']),

    permissionGroups() {
      const groups = {'admin': [], 'editor': [], 'user': []};
      for (let permission of this.allUserPermissions) {
        if (permission.toLowerCase().indexOf('admin') === 0) groups.admin.push(permission);
        else if (permission.toLowerCase().indexOf('edit') === 0) groups.editor.push(permission);
        else groups.user.push(permission);
      }
      return groups;
    }
  },
  methods: {
    ...mapActions(['getUserRoles','getUserPermissions','setLoading','setSuccess','updateUserRole','createUserRole']),
    hasPermission(permission) {
      return !!this.userRole.permissions && this.userRole.permissions.find(it => it.permission === permission)
    },
    togglePermission(permission) {
      if (this.userRole.permissions.find(it => it.permission === permission)) {
        this.userRole.permissions = this.userRole.permissions.filter(it => it.permission !== permission);
      }
      else {
        this.userRole.permissions.push({role_id: this.userRole.id, permission})
      }
    },
    async onSubmit() {
      await this.setLoading(true);
      if (this.userRole.id) { //update
        await this.updateUserRole([this.userRole, this.userRole.id])
      }
      else { // create new
        await this.createUserRole(this.userRole);
        if (this.userRole.id) {
          await this.$router.push('/role/'+this.userRole.id)
          await this.initData()
          this.userRole = {...this.userRole }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("User Role saved");
    },

    onUpdate(values) {
      this.userRole = {...this.userRole, ...values};
    },

    async initData() {
      if (this.allUserRoles.length <= 0) await this.getUserRoles();
      if (this.allUserPermissions.length <= 0) await this.getUserPermissions();

      this.userRole = !isNaN(this.$route.params.id*1) ? this.allUserRoles.find(it => it.id === this.$route.params.id*1) :
          {name: "New User Role", permissions:[]}
    },
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
