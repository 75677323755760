<template>
  <div v-if="recipe" class="panel inline">
    <div class="panel-header">
      <p>Recipe: <b>{{recipe.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="recipe" @update="onUpdate" />
            <RecipeIngredientForm :ingredients="recipe.ingredients" @add="onAddIngredient" @delete="onDeleteIngredient" @sort="onSortIngredient" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="recipe.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box" v-if="recipe.id && recipe.ingredients && recipe.ingredients.length">
              <NutritionCounter :ingredients="recipe.ingredients" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ recipe.id?'Update Recipe':'Create Recipe' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import recipeFields from "@/lib/form-fields/recipe";
import AppForm from "@/components/ui/AppForm";
import RecipeIngredientForm from "@/components/forms/RecipeIngredientForm";
import NutritionCounter from "@/components/ui/NutritionCounter";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";

const localeFields = [
  {name: "name", label:"Name", type:"text"},
  {name: "description", label:"Description", type:"textarea"},
]

export default {

  components: {LocaleForm, AppForm, RecipeIngredientForm, NutritionCounter },

  data() {
    return {
      fields : recipeFields,
      localeFields,
      recipe : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "Recipe",
  computed: {
    ...mapGetters(['isLoggedIn', 'allRecipes', 'allTags','allServingSizes','allLocales']),
  },
  methods: {
    ...mapActions(['getRecipes','getTags','setLoading','setSuccess','getServingSizes','getLocales','updateRecipe','createRecipe']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.recipe.id) { //update
        await this.updateRecipe([this.recipe, this.recipe.id])
      }
      else { // create new
        await this.createRecipe(this.recipe);
        if (this.recipe.id) {
          await this.$router.push('/recipe/'+this.recipe.id)
          await this.initData()
          this.recipe = {...this.recipe }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Recipe saved");
    },

    onUpdate(values) {
      this.recipe = {...this.recipe, ...values};
    },


    onAddIngredient(ingredient_id, serving_size_id, serving_amount, amount_g, is_dynamic) {
        this.recipe.ingredients.push({id: ingredient_id, ingredient_id, serving_size_id, serving_amount, amount_g, is_dynamic, recipe_id: this.recipe.id})
    },

    onDeleteIngredient(id) {
      this.recipe.ingredients = this.recipe.ingredients.filter(it => (it.id && it.id !== id))
    },

    onSortIngredient(oldIndex, newIndex) {
      const ing = this.recipe.ingredients.splice(oldIndex, 1);
      this.recipe.ingredients.splice(newIndex, 0, ing[0]);

      for (let i = 0; i < this.recipe.ingredients.length; i++) {
        this.recipe.ingredients[i].sort = i+1;
      }
    },

    async initData() {
      if (this.allRecipes.length <= 0) await this.getRecipes();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();
      if (this.allLocales.length <= 0) await this.getLocales();

      this.recipe = !isNaN(this.$route.params.id*1) ? this.allRecipes.find(it => it.id === this.$route.params.id*1) :
          {name: "New Recipe", ingredients:[]}

      if (this.recipe.id) await this.getRecipeLocales()
    },

    async getRecipeLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetRecipeLocales, this.recipe.id))
    },

    async createRecipeLocale(recipe_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.CreateRecipeLocale, { locale_id, name, description },  recipe_id))
    },
    async updateRecipeLocale(recipe_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.UpdateRecipeLocale, {name, description},  recipe_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, description = '', id = null}, locale_id) {
      if (id) {
        await this.updateRecipeLocale(this.recipe.id, locale_id, name, description)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, description} : it)
      }
      else {
        const res = await this.createRecipeLocale(this.recipe.id, locale_id, name, description)
        this.locales.push({id: res.insertId, locale_id, name, description})
      }
      this.settingLocale = null
    }
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
