<template>
    <div class="panel inline">
      <ConfirmModal v-if="userForDeletion" item-type="User" :item-name="userForDeletion.username" :confirm="confirmDeleteUser" :cancel="cancelDeleteUser" />
      <ListPanel title="Users" :fields="fields" :items="allUsers" :on-add="addNewUser" :on-delete="askToDeleteUser" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "Users",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      userForDeletion: null,
      search : "",
      fields : [
        {label: "Username", prop: "username", primary: true, sortable: true},
        {label: "Role", prop: "role_id", sortable: true, format: (id) => { const role = this.allUserRoles.find(it => it.id === id);  return role ? role.name : '-'  } },
        {label: "Locale", prop: "locale_id", sortable: true, format: (id) => { const locale = this.allLocales.find(it => it.id === id);  return locale ? locale.description : '-'  } },
        {label: "Plan", prop: "plan_id", sortable: true, format: (id) => { const plan = this.allPlans.find(it => it.id === id);  return plan ? plan.name : '-'  } },
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allUsers', 'allUserRoles', 'allLocales', 'allPlans']),
  },

  methods: {
    ...mapActions(['getUsers','getUserRoles','setLoading','deleteUser', 'getLocales', 'getPlans']),
    goto(user) {
      this.$router.push('/user/'+user.id)
    },
    addNewUser() {
      this.$router.push('/user/new')
    },
    askToDeleteUser(user) {
      this.userForDeletion = user;
    },
    cancelDeleteUser() { this.userForDeletion = null },
    confirmDeleteUser() {
      this.deleteUser(this.userForDeletion.id)
      this.userForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allUserRoles.length <= 0) await this.getUserRoles();
        if (this.allLocales.length <= 0) await this.getLocales();
        if (this.allPlans.length <= 0) await this.getPlans();
        if (this.allUsers.length <= 0) await this.getUsers();
      },800)
    }
    else if (this.allUsers.length <= 0) await this.getUsers();
  },
}
</script>
