import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        plans : [],
        plan: null
    },
    getters : {
        allPlans: state => state.plans,
        myPlan: state => state.plan
    },
    actions : {
        async deletePlan({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlan, id))
            commit('deletePlan', id)
        },
        async getPlans({commit}) {
            const plans = await ApiRequest(ApiCall(Api.ListPlans));
            commit('setPlans', plans);
        },
        async updatePlan({commit}, [plan, id]) {
            await ApiRequest(ApiCall(Api.UpdatePlan, plan, id))
            commit('savePlan', [plan, id])
        },
        async createPlan({commit}, plan) {
            const res = await ApiRequest(ApiCall(Api.CreatePlan, plan))
            plan.id = res.insertId
            commit('savePlan', [plan, null])
        },
        async setEditorTitle({commit}, [id, editor_title]) {
            await ApiRequest(ApiCall(Api.SetPlanSettings, {editor_title}))
            commit('setEditorTitle', [id, editor_title]);
        },
        async setPlanRules({commit}, [id, rules]) {
            await ApiRequest(ApiCall(Api.SetPlanSettings, {rules}))
            commit('setPlanRules', [id, rules]);
        },
        async setPlanInputs({commit}, [id, inputs, sorting]) {
            await ApiRequest(ApiCall(Api.SetPlanSettings, {inputs, sorting}))
            commit('setPlanInputs', [id, inputs, sorting]);
        },
        async setPlanmealRepOffsets({commit}, [id, meal_rep_offsets]) {
            await ApiRequest(ApiCall(Api.SetPlanSettings, {meal_rep_offsets}))
            commit('setPlanmealRepOffsets', [id, meal_rep_offsets]);
        },
        async getMyPlan({commit}) {
            const plan = await ApiRequest(ApiCall(Api.GetMyPlan));
            commit('setMyPlan', plan);
        },
        async setMyPlan({commit}, plan) {
            commit('setMyPlan', plan);
        }
    },
    mutations : {
        setPlans: (state, plans) => state.plans = plans,
        savePlan: (state, [plan, id]) => {
            if (id) {
                state.plans = state.plans.map(it => it.id === plan.id ? plan : it)
                if (state.plan && state.plan.id === plan.id) state.plan = plan;
            }
            else {
                state.plans = [...state.plans, plan];
            }
            return state
        },
        deletePlan(state, id) {
            state.plans = state.plans.filter(it => it.id !== id)
        },
        setEditorTitle(state, [id, editor_title]) {
            state.plans = state.plans.map(it => it.id === id ? {...it, editor_title} : it)
        },
        setPlanRules(state, [id, rules]) {
            state.plans = state.plans.map(it => it.id === id ? {...it, rules} : it)
        },
        setPlanInputs(state, [id, inputs, sorting]) {
            state.plans = state.plans.map(it => it.id === id ? {...it, inputs, sorting} : it)
        },
        setPlanmealRepOffsets(state, [id, meal_rep_offsets]) {
            state.plans = state.plans.map(it => it.id === id ? {...it, ...meal_rep_offsets} : it)
        },
        setMyPlan(state, plan) {
            state.plan = plan;

        }
    }
}
