import Home from "@/components/views/Home";
import Ingredients from "@/components/views/Editor/Ingredients";
import Ingredient from "@/components/views/Editor/Ingredient";
import Locales from "@/components/views/Editor/Locales";
import Locale from "@/components/views/Editor/Locale";
import Meals from "@/components/views/Editor/Meals";
import Meal from "@/components/views/Editor/Meal";
import Plans from "@/components/views/Editor/Plans";
import Plan from "@/components/views/Editor/Plan";
import Markets from "@/components/views/Editor/Markets";
import Market from "@/components/views/Editor/Market";
import Recipes from "@/components/views/Editor/Recipes";
import Recipe from "@/components/views/Editor/Recipe";
import Roles from "@/components/views/Admin/Roles";
import Role from "@/components/views/Admin/Role";
import ServingSizes from "@/components/views/Editor/ServingSizes";
import ServingSize from "@/components/views/Editor/ServingSize";
import Tags from "@/components/views/Editor/Tags";
import Tag from "@/components/views/Editor/Tag";
import Users from "@/components/views/Admin/Users";
import User from "@/components/views/Admin/User";
import PlanMeals from "@/components/views/Plan/PlanMeals";
import PlanMeal from "@/components/views/Plan/PlanMeal";
import PlanIngredients from "@/components/views/Plan/PlanIngredients";
import PlanIngredient from "@/components/views/Plan/PlanIngredient";
import Groups from "@/components/views/Plan/Groups";
import PrefGroup from "@/components/views/Plan/PrefGroup";
import RepGroup from "@/components/views/Plan/RepGroup";
import PlanIngredientIcons from "@/components/views/Plan/PlanIngredientIcons";
import PlanIngredientIcon from "@/components/views/Plan/PlanIngredientIcon";
import MenuBuilder from "@/components/views/Plan/MenuBuilder";
import MealTypes from "@/components/views/Editor/MealTypes";
import MealType from "@/components/views/Editor/MealType";
import PlanSettings from "@/components/views/Plan/PlanSettings";
import MealStyles from "@/components/views/Editor/MealStyles";
import MealStyle from "@/components/views/Editor/MealStyle";
import PlanMealReplacements from "@/components/views/Plan/PlanMealReplacements";

export default [
    // admin routes
    { path: '/roles', component: Roles, keepAlive: true },
    { path: '/role/:id', component: Role, keepAlive: true },
    { path: '/users', component: Users, keepAlive: true },
    { path: '/user/:id', component: User, keepAlive: true },

    // editor routes

    { path: '/', component: Home, keepAlive: true },
    { path: '/meal-types', component: MealTypes, keepAlive: true },
    { path: '/meal-type/:id', component: MealType, keepAlive: true },
    { path: '/meal-styles', component: MealStyles, keepAlive: true },
    { path: '/meal-style/:id', component: MealStyle, keepAlive: true },
    { path: '/ingredients', component: Ingredients, keepAlive: true },
    { path: '/ingredient/:id', component: Ingredient, keepAlive: true },
    { path: '/locales', component: Locales, keepAlive: true },
    { path: '/locale/:id', component: Locale, keepAlive: true },
    { path: '/meals', component: Meals, keepAlive: true },
    { path: '/meal/:id', component: Meal, keepAlive: true },
    { path: '/plans', component: Plans, keepAlive: true },
    { path: '/plan/:id', component: Plan, keepAlive: true },
    { path: '/markets', component: Markets, keepAlive: true },
    { path: '/market/:id', component: Market, keepAlive: true },
    { path: '/recipes', component: Recipes, keepAlive: true },
    { path: '/recipe/:id', component: Recipe, keepAlive: true },
    { path: '/serving-sizes', component: ServingSizes, keepAlive: true },
    { path: '/serving-size/:id', component: ServingSize, keepAlive: true },
    { path: '/tags', component: Tags, keepAlive: true },
    { path: '/tag/:id', component: Tag, keepAlive: true },

    // plan routes

    { path: '/plan-meals', component: PlanMeals, keepAlive: false},
    { path: '/plan-meal-replacements', component: PlanMealReplacements, keepAlive: true },
    { path: '/plan-meal/:id', component: PlanMeal, keepAlive: false},
    { path: '/plan-ingredients', component: PlanIngredients, keepAlive: false},
    { path: '/plan-ingredient/:id', component: PlanIngredient, keepAlive: false},

    { path: '/plan-groups', component: Groups, keepAlive: false},
    { path: '/pref-group/:id', component: PrefGroup, keepAlive: false},
    { path: '/rep-group/:id', component: RepGroup, keepAlive: false},

    { path: '/plan-ingredient-icons', component: PlanIngredientIcons, keepAlive: false},
    { path: '/plan-ingredient-icon/:id', component: PlanIngredientIcon, keepAlive: false},

    { path: '/plan-menu', component: MenuBuilder, keepAlive: false},
    { path: '/plan-settings', component: PlanSettings, keepAlive: false},
    { path: '/plan-settings/:section', component: PlanSettings, keepAlive: false},
];
