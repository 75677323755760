import Leaf from "./Leaf";

export default function Tree(sizes, options = {}) {
    this.options = {};
    this.cache = {};
    this.sources = {};
    this.sorting = null;
    this.accumulated = {};
    this.size = 0;
    this.sizes = null;
    this.computed = {}

    this.tree = [];
    this.seeds = 0;
    this.seedUp = () => this.seeds++;

    this.buckets = null;

    this._init = (sizes = null, opts = {}) => {

        let bux = [...opts.buckets];

        // sizes by designator..?
        if (opts.designator && opts.data && bux) {
            this.buckets = []
            sizes = []
            bux = bux.filter(it => !it.inactive);
            let des, assigned = 0;
            for (let b = 0; b < bux.length; b++) {
                if (!sizes[b]) sizes[b] = 0;
                for (let d of opts.data) {
                    des = opts.designator(d, bux[b]);
                    if (des) {
                        sizes[b]++; assigned++;
                        if (!this.buckets[b]) this.buckets[b] = [];
                        this.buckets[b].push(d);
                    }
                }
            }

            this.sizes = sizes;
            if (assigned < opts.data.length) {
                console.warn("Only assigned",assigned,"out of",opts.data.length,"total entries to buckets.")
            }
        }

        else {
        // Assign root nodes, which then point to their children via recursion
            sizes = sizes.map(it => it instanceof Array ? it.length : it); // map to 1-D array of bucket sizes
            this.sizes = sizes;
        }

        this.size = sizes.length;
        for (let i = 0; i < sizes[0]; i++) {
            this.tree.push(new Leaf(i, 0, sizes, bux, this.seedUp));
        }

        this._parseOptions(opts);
    }

    this._addSource = (sourceName, source) => {
        if (typeof source === "function") {
            source = {
                name: sourceName + ' (raw)',
                fetch : source
            }
        }

        if (source.computed) {
            this.computed[sourceName] = source.computed;
        }

        this.sources[sourceName] = (index, key) => {
            if (!this.cache[sourceName]) this.cache[sourceName] = {}
            const res = this.cache[sourceName][index+'~'+key] ? this.cache[sourceName][index+'~'+key] : source.fetch(this.buckets, index, key);
            if (!this.cache[sourceName][index+'~'+key]) this.cache[sourceName][index+'~'+key] = res;

            return res
        }
    }

    this._parseOptions = (options) => {
        this.options = {...this.options, ...options};
        if (options.sources) {
            for (let source in options.sources) {
                this._addSource(source, options.sources[source])
            }
        }
        if (options.sorting) {
            this.sorting = options.sorting;
        }
    }

    this.run = (options = {}, params = {}) => {
        this._parseOptions(options);

        let res = [];

        // test the tree
        for (let t = 0; t < this.tree.length; t++) {
            this.tree[t].start(t, res, {...this.options, sources: this.sources, computed: this.computed, last: this.size }, this.accumulated, params);
        }

        return res;
    }

    this.reset = (sizes = null, options = {}) => {
        this.cache = {};
        this.accumulated = {};
        this.size = 0;
        this.sizes = null;
        this.buckets = null;

        this.tree = [];
        this.seeds = 0;
        this._init(sizes || this.sizes, {...this.options, options});
    }

    this.getData = (res, source) => res.map(it => this.accumulated[source][it])

    this.sortResults = (res, params) => {
        if (!this.sorting) return res;

        return this.sorting(res, this.accumulated, params);
    }

    this.extractResult = (data, key) => {
        const res = []; let kkey
        for (let i = 0; i < key.length; i++) {
            kkey = parseInt(key[i], 36);
            res.push(data[i][kkey])
        }
        return res;
    }

    this.getSingleton = () => () => this;

    this._init(sizes, options);

}
