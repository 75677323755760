<template>
  <div v-if="mealType" class="panel inline">
    <div class="panel-header">
      <p>Meal Type: <b>{{mealType.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="mealType" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ mealType.id?'Update Meal Type':'Create Meal Type' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import mealTypeFields from "@/lib/form-fields/mealType";
import AppForm from "@/components/ui/AppForm";

export default {

  components: { AppForm },

  data() {
    return {
      fields : mealTypeFields,
      mealType : null,
    }
  },

  name: "MealType",
  computed: {
    ...mapGetters(['isLoggedIn', 'allMealTypes', 'allTags','allServingSizes']),
  },
  methods: {
    ...mapActions(['getMealTypes','getTags','setLoading','setSuccess','getServingSizes','updateMealType','createMealType']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.mealType.id) { //update
        await this.updateMealType([this.mealType, this.mealType.id])
      }
      else { // create new
        await this.createMealType(this.mealType);
        if (this.mealType.id) {
          await this.$router.push('/meal-type/'+this.mealType.id)
          await this.initData()
          this.mealType = {...this.mealType }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Meal Type saved");
    },

    onUpdate(values) {
      this.mealType = {...this.mealType, ...values};
    },

    async initData() {
      if (this.allMealTypes.length <= 0) await this.getMealTypes();
      if (this.allTags.length <= 0) await this.getTags();
      if (this.allServingSizes.length <= 0) await this.getServingSizes();

      this.mealType = !isNaN(this.$route.params.id*1) ? this.allMealTypes.find(it => it.id === this.$route.params.id*1) :
          {name: "New Meal Type"}

    },
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
