<template>
  <div class="box">
    <div class="even">
      <div v-for="(input, index) in inputs" :key="index">
        <h3>{{input}}</h3>
        <div class="form-input">
          <input :value="data[input]" type="number" @change="(v) => onInputChange(input, v.target.value)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ['onChange','inputs','data'],
    data() {
        return {

        }
    },
    methods: {
        onInputChange(prop, value) {
            this.onChange(prop, value);
        }
    },
}
</script>
