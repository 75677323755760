<template>
    <div class="panel inline">
      <ConfirmModal v-if="planIngredientIconForDeletion" item-type="Icon" :item-name="null" :confirm="confirmDeleteIcon" :cancel="cancelDeleteIcon" />
      <ListPanel :fields="fields" title="Icons" :items="allPlanIngredientIcons" :on-row="goto" :on-delete="askToDeleteIcon" :on-add="addNewIcon" />
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";
import {delayedIf} from "@/lib/Async";

export default {
  name: "Icons",
  components: {ListPanel, ConfirmModal },
  data() {
    return {
      planIngredientIconForDeletion: null,
      search : "",
      fields : [
        {label: "Color", prop: "color", width: "20%", primary: true, color: true},
        {label: "Image", prop: "image", image: true, imageBackground: 'color'},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanIngredientIcons']),
    filteredIcons() {
      return this.search.length >= 1 ?
          this.allPlanIngredientIcons.filter(it => it.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) :
          this.allPlanIngredientIcons
    }
  },

  methods: {
    ...mapActions(['getPlanIngredientIcons','setLoading','deletePlanIngredientIcon']),
    goto(planIngredientIcon) {
      this.$router.push('/plan-ingredient-icon/'+planIngredientIcon.id)
    },
    addNewIcon() {
      this.$router.push('/plan-ingredient-icon/new')
    },
    askToDeleteIcon(planIngredientIcon) {
      this.planIngredientIconForDeletion = planIngredientIcon;
    },
    cancelDeleteIcon() { this.planIngredientIconForDeletion = null },
    confirmDeleteIcon() {
      this.deletePlanIngredientIcon(this.planIngredientIconForDeletion.id)
      this.planIngredientIconForDeletion = null
    }
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else {
        if (this.allPlanIngredientIcons.length <= 0) await this.getPlanIngredientIcons();
      }
      await this.setLoading(false)
    }, 800)
  },
}
</script>
