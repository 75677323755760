<template>
    <div class="panel inline">
      <ConfirmModal v-if="servingSizeForDeletion" item-type="Serving Size" :item-name="servingSizeForDeletion.name" :confirm="confirmDeleteServingSize" :cancel="cancelDeleteServingSize" />
      <ListPanel title="Serving Sizes" :fields="fields" :items="allServingSizes" :on-add="addNewServingSize" :on-delete="askToDeleteServingSize" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "ServingSizes",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      servingSizeForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", primary: true, sortable: true},
        {label: "Amount (G)", prop: "amount_g", width: "10%", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allServingSizes']),
  },

  methods: {
    ...mapActions(['getServingSizes','setLoading','deleteServingSize']),
    goto(servingSize) {
      this.$router.push('/serving-size/'+servingSize.id)
    },
    addNewServingSize() {
      this.$router.push('/serving-size/new')
    },
    askToDeleteServingSize(servingSize) {
      this.servingSizeForDeletion = servingSize;
    },
    cancelDeleteServingSize() { this.servingSizeForDeletion = null },
    confirmDeleteServingSize() {
      this.deleteServingSize(this.servingSizeForDeletion.id)
      this.servingSizeForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allServingSizes.length <= 0) await this.getServingSizes();
      },800)
    }
    else if (this.allServingSizes.length <= 0) await this.getServingSizes();
  },
}
</script>
