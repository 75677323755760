import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        userRoles : []
    },
    getters : {
        allUserRoles: state => state.userRoles
    },
    actions : {
        async deleteUserRole({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteUserRole, id))
            commit('deleteUserRole', id)
        },
        async getUserRoles({commit}) {
            const userRoles = await ApiRequest(ApiCall(Api.ListUserRoles));
            commit('setUserRoles', userRoles);
        },
        async updateUserRole({commit}, [userRole, id]) {
            await ApiRequest(ApiCall(Api.UpdateUserRole, userRole, id))
            commit('saveUserRole', [userRole, id])
        },
        async createUserRole({commit}, userRole) {
            const res = await ApiRequest(ApiCall(Api.CreateUserRole, userRole))
            userRole.id = res.insertId
            commit('saveUserRole', [userRole, null])
        },
    },
    mutations : {
        setUserRoles: (state, userRoles) => state.userRoles = userRoles,
        saveUserRole: (state, [userRole, id]) => {
            if (id) {
                state.userRoles = state.userRoles.map(it => it.id === userRole.id ? userRole : it)
            }
            else {
                state.userRoles = [...state.userRoles, userRole];
            }
            return state
        },
        deleteUserRole(state, id) {
            state.userRoles = state.userRoles.filter(it => it.id !== id)
        },
    }
}
