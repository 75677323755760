<template>
  <div v-if="planIngredientIcon" class="panel inline">
    <div class="panel-header">
      <p>Ingredient Icon: <b>{{planIngredientIcon.id}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <IngredientIconForm :icon="planIngredientIcon" @update="updateIconProp" :existing="allPlanIngredientIcons" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ planIngredientIcon.id?'Update Icon':'Create Icon' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import IngredientIconForm from "@/components/forms/IngredientIconForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import {delayedIf} from "@/lib/Async";

export default {

  components: {IngredientIconForm, },

  data() {
    return {
      planIngredientIcon : null,
      ingredients: [],
    }
  },

  name: "PlanIngredientIcon",
  computed: {
    ...mapGetters(['isLoggedIn', 'allPlanIngredientIcons']),
  },
  methods: {
    ...mapActions(['getPlanIngredientIcons', 'setLoading', 'setSuccess', 'updatePlanIngredientIcon', 'createPlanIngredientIcon']),

    async onSubmit() {
      let isNew = false;
      await this.setLoading(true);

      if (this.planIngredientIcon.id) { //update
        await this.updatePlanIngredientIcon([this.planIngredientIcon, this.planIngredientIcon.id])
      } else { // create new
        isNew = true;
        await this.createPlanIngredientIcon(this.planIngredientIcon);
      }
      if (this.planIngredientIcon.file) {
        await ApiRequest(ApiCall(Api.UploadPlanIngredientIcon, {file: this.planIngredientIcon.file}, this.planIngredientIcon.id))
      }

      if (isNew && this.planIngredientIcon.id) {
        await this.$router.push('/plan-ingredient-icon/' + this.planIngredientIcon.id)
        await this.initData()
        this.planIngredientIcon = {...this.planIngredientIcon}
      }

      await this.setLoading(false);
      await this.setSuccess("Icon saved");
    },

    updateIconProp([prop, value]) {
      this.planIngredientIcon[prop] = value;
    },

    onUpdate(values) {
      this.planIngredientIcon = {...this.planIngredientIcon, ...values};
    },

    async initData() {
      if (this.allPlanIngredientIcons.length <= 0) await this.getPlanIngredientIcons();
      this.planIngredientIcon = !isNaN(this.$route.params.id * 1) ? this.allPlanIngredientIcons.find(it => it.id === this.$route.params.id * 1) : {color:"#000000"}
    },
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else await this.initData()
      await this.setLoading(false)
    }, 800)
  },


}
</script>
