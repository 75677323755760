import RuleTypes from "./RuleTypes";
export default function Leaf(key, index, sizes, buckets, seedUp) {
    this.data = key;
    this.next = [];

    // initial population via recursion
    if (index < sizes.length-1) {
        for (let i = 0; i < sizes[index+1]; i++) {
            if (index >= buckets.length - 2) seedUp();
            this.next.push(new Leaf(i, index+1, sizes, buckets, seedUp));
        }
    }

    this.test = (rules, position, options, accumulated, params) => {
        for (let rule of rules) {
            if (rule.inactive) {
                continue;
            }
            let ruleFunc, r;

            switch (rule.type) {
                case 'boolean': ruleFunc = RuleTypes.Boolean; break;
                case 'sum-range': ruleFunc = RuleTypes.Range; break;
                case 'bucket-compare': ruleFunc = RuleTypes.BucketCompare; break;
            }

            if (ruleFunc) {
                r = ruleFunc(
                    position, rule.data, accumulated[rule.source],
                    rule.source && options.sources[rule.source] ? options.sources[rule.source] : () => false,
                    params, options.buckets)
                if (!r) return false
            }

        }
        return true;
    }

    this._applyComputed = (key, accumulated, computed, s, params) => {
        if (computed[s]) {
            for (let ac in computed[s]) {
                accumulated[s][key][ac] = computed[s][ac](accumulated[s][key], params)
            }
        }
    }

    this.accumulate = (accumulated, sources, computed, index, key, prev, params) => {
        prev = prev+'';

        let accRes;
        for (let s in sources) {
            if (!accumulated[s+'']) accumulated[s+''] = {};

            if (accumulated[s+''][prev+key]) {
                this._applyComputed(prev+key, accumulated, computed, s+'', params);
                continue;
            }
            accumulated[s+''][prev+key] = prev && accumulated[s+''][prev] ? { ...accumulated[s+''][prev] } : {};
            accRes = sources[s+''](index, key);
            for (let d in accRes) {
                if (!isNaN(accRes[d+'']*1)) {
                    accumulated[s+''][prev+key][d+''] = (accumulated[s+''][prev] ? accumulated[s+''][prev][d+''] : 0) + accRes[d+'']*1;
                }
            }
            this._applyComputed(prev+key, accumulated, computed, s, params);
        }
        return accumulated;
    }

    this.start = (key, res, options, accumulated, params) => {
        const position = {
            hash: '',
            fromIndex: 0,
            toIndex: 0,
            fromKey: key,
            toKey: key,
            last : (key+'').length === options.last
        };

        if (!this.test(options.rules, position, options, accumulated, params)) {
            // ...
        }
        else {
            this.do(res, options, accumulated, params, '');
        }
    }

    this.do = (res, options, accumulated, params, prev = '') => {

        const kkey = key.toString(36);
        let n;

        const position = {
            hash: prev+kkey+'',
            fromIndex: index,
            toIndex: index+1,
            fromKey: kkey,
            last : index+1 === options.last-1,
            toKey: null
        };

        this.accumulate(accumulated, options.sources, options.computed, index, kkey, prev, params);

        if (index < sizes.length - 1) { // more iterations ahead
            for (n = 0; n < this.next.length; n++) {
                position.toKey = n;
                if (position.last) {
                    this.accumulate(accumulated, options.sources, options.computed, position.toIndex, position.toKey, position.hash, params)
                }
                if (this.test(options.rules, position, options, accumulated, params)) {
                    this.next[n].do(res, options, accumulated, params, prev.toString() + kkey);
                }
            }
        }
        else {
            // we're done!
            if ((prev+kkey).length > options.last) return;

            this.accumulate(accumulated, options.sources, options.computed, index, kkey, prev, params);

            res.push(prev+kkey);
        }
    }
}
