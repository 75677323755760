<template>
  <div v-if="repGroup" class="panel inline">
    <div class="panel-header">
      <p>Replacement Group: <b>{{repGroup.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="repGroup" @update="onUpdate" />
            <GroupIngredientForm v-if="repGroup && repGroup.id" :ingredients="ingredients" @add="onAddIngredient" @delete="onDeleteIngredient" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ repGroup.id?'Update Replacement Group':'Create Replacement Group' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import repGroupFields from "@/lib/form-fields/repGroup";
import AppForm from "@/components/ui/AppForm";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import GroupIngredientForm from "@/components/forms/GroupIngredientForm";
import {delayedIf} from "@/lib/Async";

export default {

  name: "RepGroup",

  components: {GroupIngredientForm, AppForm, },

  data() {
    return {
      fields : repGroupFields,
      repGroup : null,
      ingredients: [],
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allRepGroups']),
  },
  methods: {
    ...mapActions(['getRepGroups', 'getPlanIngredients', 'setLoading', 'setSuccess', 'updateRepGroup', 'createRepGroup']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.repGroup.id) { //update
        await this.updateRepGroup([this.repGroup, this.repGroup.id])
      } else { // create new
        await this.createRepGroup(this.repGroup);
        if (this.repGroup.id) {
          await this.$router.push('/rep-group/' + this.repGroup.id)
          await this.initData()
          this.repGroup = {...this.repGroup}
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Replacement Group saved");
    },

    onUpdate(values) {
      this.repGroup = {...this.repGroup, ...values};
    },

    async onAddIngredient(ingredient) {
      await ApiRequest(ApiCall(Api.UpdatePlanIngredient, {...ingredient, rep_group_id: this.repGroup.id}, ingredient.id))
      this.ingredients.push(ingredient)

      await this.getPlanIngredients()
    },

    async onDeleteIngredient(ingredient) {
      await ApiRequest(ApiCall(Api.UpdatePlanIngredient, {rep_group_id: null}, ingredient.id));
      this.ingredients = this.ingredients.filter(it => it.id !== ingredient.id)
      await this.getPlanIngredients()

    },

    async initData() {
      if (this.allRepGroups.length <= 0) await this.getRepGroups();
      this.repGroup = !isNaN(this.$route.params.id * 1) ? this.allRepGroups.find(it => it.id === this.$route.params.id * 1) : {ingredients:[]}

      this.ingredients = await ApiRequest(ApiCall(Api.GetRepGroupIngredients, this.$route.params.id))
    },
  },

  async mounted() {
    await this.setLoading(true)
    await delayedIf(!this.isLoggedIn, async () => {
      if (!this.isLoggedIn) await this.$router.push('/')
      else await this.initData()
      await this.setLoading(false)
    }, 800)
  },


}
</script>
