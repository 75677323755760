import Vuex from 'vuex';
import Vue from 'vue';

import ingredients from './modules/ingredients';
import locale from './modules/locale';
import login from './modules/login';
import meals from './modules/meals';
import recipes from './modules/recipes';
import markets from './modules/markets';
import mealStyles from './modules/meal-styles';
import mealTypes from './modules/meal-types';
import plans from './modules/plans';
import prefGroups from './modules/pref-groups';
import repGroups from './modules/rep-groups';
import tags from './modules/tags';
import servingSizes from './modules/serving-sizes';
import userRoles from './modules/user-roles';
import users from './modules/users';
import userPermissions from './modules/user-permissions';

Vue.use(Vuex);

export default new Vuex.Store({
    modules : {
        locale,
        login,
        recipes,
        ingredients,
        meals,
        plans,
        prefGroups,
        markets,
        mealStyles,
        mealTypes,
        repGroups,
        servingSizes,
        tags,
        users,
        userRoles,
        userPermissions,
    }
})
