import Api, {ApiCall, ApiRequest} from "@/lib/Api";

export default {
    state : {
        meals : [],
        planMeals: [],
    },
    getters : {
        allMeals: state => state.meals,
        allPlanMeals: state => state.planMeals
    },
    actions : {
        async getMeals({commit}) {
            const meals = await ApiRequest(ApiCall(Api.ListMeals));
            commit('setMeals', meals);
        },
        async updateMeal({commit}, [meal, id]) {
            await ApiRequest(ApiCall(Api.UpdateMeal, meal, id))
            commit('saveMeal', [meal, id])
        },
        async createMeal({commit}, meal) {
            const res = await ApiRequest(ApiCall(Api.CreateMeal, meal))
            meal.id = res.insertId
            commit('saveMeal', [meal, null])
        },
        async deleteMeal({commit}, id) {
            await ApiRequest(ApiCall(Api.DeleteMeal, id))
            commit('deleteMeal', id)
        },

        async getPlanMeals({commit}) {
            const meals = await ApiRequest(ApiCall(Api.ListPlanMeals));
            commit('setPlanMeals', meals);
        },
        async createPlanMeal({commit}, meal) {
            const res = await ApiRequest(ApiCall(Api.CreatePlanMeal, meal))
            meal.id = res.id
            commit('createPlanMeal', meal);
        },
        async updatePlanMeal({commit}, [meal, id]) {
            await ApiRequest(ApiCall(Api.UpdatePlanMeal, meal, id))
            commit('savePlanMeal', [meal, id])
        },
        async deletePlanMeal({commit}, id) {
            await ApiRequest(ApiCall(Api.DeletePlanMeal, id))
            commit('deletePlanMeal', id);
        },

    },
    mutations : {
        setPlanMeals: (state, planMeals) => state.planMeals = planMeals,
        setMeals: (state, meals) => state.meals = meals,
        saveMeal: (state, [meal, id]) => {
            if (id) {
                state.meals = state.meals.map(it => it.id === meal.id ? meal : it)
            }
            else {
                state.meals = [...state.meals, meal];
            }
            return state
        },
        createPlanMeal(state, meal) {
            state.planMeals.push(meal)
        },
        deleteMeal(state, id) {
            state.meals = state.meals.filter(it => it.id !== id)
        },
        deletePlanMeal(state, id) {
            state.planMeals = state.planMeals.filter(it => it.id !== id)
        },
        savePlanMeal : (state,[meal,id]) => {
            if (id) {
                state.planMeals = state.planMeals.map(it => it.id === meal.id ? meal : it)
            }
            else {
                state.planMeals = [...state.planMeals, meal]
            }
        }
    }
}
