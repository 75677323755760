import {getToken} from './Storage';

export default {
    // login
    CheckLogin : { path : "/user/check-login", method: "get", token: true},
    Login : { path : '/user/login', method: "post", body: true },
    Logout : { path: '/user/logout', method: "get", token: true },

    // admin

        // locales
        CreateLocale : { path: '/locale/add', method: "post", body: true, token: true },
        UpdateLocale : { path: '/locale/edit', method: "post", body: true, param: true, token: true },
        DeleteLocale : { path: '/locale/delete', method: "get", param: true, token: true },

        // users
        ListUsers : { path: '/user/list', method: "get", token: true },
        CreateUser : { path: '/user/add', method: "post", body: true, token: true },
        UpdateUser : { path: '/user/edit', method: "post", body: true, param: true, token: true },
        DeleteUser : { path: '/user/delete', method: "get", param: true, token: true },

        // roles
        ListUserPermissions : { path: '/user-roles/permissions', method: "get", token: true },
        ListUserRoles : { path: '/user-roles/list', method: "get", token: true },
        CreateUserRole : { path: '/user-roles/add', method: "post", body: true, token: true },
        UpdateUserRole : { path: '/user-roles/edit', method: "post", body: true, param: true, token: true },
        DeleteUserRole : { path: '/user-roles/delete', method: "get", param: true, token: true },

    // editor

        // Set Plan
        SetCurrentPlan : { path: '/user/set-plan', method: "get", param: true, token: true },

        // locales
        ListLocales : { path: '/locale/list', method: "get", token: true },

        // meal types
        ListMealTypes : { path: '/meal-types/list', method: "get", token: true },
        CreateMealType : { path: '/meal-types/add', method: "post", body: true, token: true },
        UpdateMealType : { path: '/meal-types/edit', method: "post", body: true, param: true, token: true },
        DeleteMealType : { path: '/meal-types/delete', method: "get", param: true, token: true },
        GetMealTypeLocales : { path: '/meal-types/locales', method: "get", param: true, token: true },
        CreateMealTypeLocale : { path: '/meal-types/add', method: "post", body: true, param: true, token: true },
        UpdateMealTypeLocale : { path: '/meal-types/edit', method: "post", body: true, param: true, token: true },

        // meal styles
        ListMealStyles : { path: '/meal-styles/list', method: "get", token: true },
        CreateMealStyle : { path: '/meal-styles/add', method: "post", body: true, token: true },
        UpdateMealStyle : { path: '/meal-styles/edit', method: "post", body: true, param: true, token: true },
        DeleteMealStyle : { path: '/meal-styles/delete', method: "get", param: true, token: true },
        GetMealStyleLocales : { path: '/meal-styles/locales', method: "get", param: true, token: true },
        CreateMealStyleLocale : { path: '/meal-styles/add', method: "post", body: true, param: true, token: true },
        UpdateMealStyleLocale : { path: '/meal-styles/edit', method: "post", body: true, param: true, token: true },

        // serving sizes
        CreateServingSize : { path: '/serving-sizes/add', method: "post", body: true, token: true },
        UpdateServingSize : { path: '/serving-sizes/edit', method: "post", body: true, param: true, token: true },
        DeleteServingSize : { path: '/serving-sizes/delete', method: "get", param: true, token: true },
        GetServingSizeLocales : { path: '/serving-sizes/locales', method: "get", param: true, token: true },
        CreateServingSizeLocale : { path: '/serving-sizes/add', method: "post", body: true, param: true, token: true },
        UpdateServingSizeLocale : { path: '/serving-sizes/edit', method: "post", body: true, param: true, token: true },


        // markets
        ListMarkets : { path: '/markets/list', method: "get", token: true },
        CreateMarket : { path: '/markets/add', method: "post", body: true, token: true },
        UpdateMarket : { path: '/markets/edit', method: "post", body: true, param: true, token: true },
        DeleteMarket : { path: '/markets/delete', method: "get", param: true, token: true },
        GetMarketLocales : { path: '/markets/locales', method: "get", param: true, token: true },
        CreateMarketLocale : { path: '/markets/add', method: "post", body: true, param: true, token: true },
        UpdateMarketLocale : { path: '/markets/edit', method: "post", body: true, param: true, token: true },

        // tags
        CreateTag : { path: '/tags/add', method: "post", body: true, token: true },
        UpdateTag : { path: '/tags/edit', method: "post", body: true, param: true, token: true },
        DeleteTag : { path: '/tags/delete', method: "get", param: true, token: true },
        GetTagLocales : { path: '/tags/locales', method: "get", param: true, token: true },
        CreateTagLocale : { path: '/tags/add', method: "post", body: true, param: true, token: true },
        UpdateTagLocale : { path: '/tags/edit', method: "post", body: true, param: true, token: true },

        // meals
        ListMeals : { path: '/meals/list', method: "get", token: true },
        GetMealLocales : { path: '/meals/locales', method: "get", param: true, token: true },
        CreateMealLocale : { path: '/meals/add', method: "post", body: true, param: true, token: true },
        UpdateMealLocale : { path: '/meals/edit', method: "post", body: true, param: true, token: true },

        CreateMeal : { path: '/meals/add', method: "post", body: true, token: true },
        UpdateMeal : { path: '/meals/edit', method: "post", body: true, param: true, token: true },
        DeleteMeal : { path: '/meals/delete', method: "get", param: true, token: true },

        ListPlanMeals : {path: '/meals/plan', method: "get", token: true},
        CreatePlanMeal: {path: '/meals/plan/add', method: "post", body: true, token: true},
        UpdatePlanMeal: {path: '/meals/plan/edit', method: "post", body: true, param: true, token: true},
        DeletePlanMeal: {path: '/meals/plan/delete', method: "post", param: true, token: true},

        // recipes
        ListRecipes : { path: '/recipes/list', method: "get", token: true },
        GetRecipeLocales : { path: '/recipes/locales', method: "get", param: true, token: true },
        CreateRecipeLocale : { path: '/recipes/add', method: "post", body: true, param: true, token: true },
        UpdateRecipeLocale : { path: '/recipes/edit', method: "post", body: true, param: true, token: true },

        CreateRecipe : { path: '/recipes/add', method: "post", body: true, token: true },
        UpdateRecipe : { path: '/recipes/edit', method: "post", body: true, param: true, token: true },
        DeleteRecipe : { path: '/recipes/delete', method: "get", param: true, token: true },

        // plans
        ListPlans : { path: '/plan/list', method: "get", token: true },
        GetPlanLocales : { path: '/plan/locales', method: "get", param: true, token: true },
        CreatePlanLocale : { path: '/plan/add', method: "post", body: true, param: true, token: true },
        UpdatePlanLocale : { path: '/plan/edit', method: "post", body: true, param: true, token: true },

        CreatePlan : { path: '/plan/add', method: "post", body: true, token: true },
        UpdatePlan : { path: '/plan/edit', method: "post", body: true, param: true, token: true },
        DeletePlan : { path: '/plan/delete', method: "get", param: true, token: true },

        SetPlanSettings : { path: '/plan', method: "post", body: true, token: true},
        ListPlanMealTypes : { path: '/plan/meal-types', method: "get", token: true },
        SetPlanMealTypes : { path: '/plan/meal-types', method: "post", body: true, token: true},
        ListPlanMealStyles : { path: '/plan/meal-styles', method: "get", token: true },
        SetPlanMealStyles : { path: '/plan/meal-styles', method: "post", body: true, token: true},

    // ingredients
        CreateIngredientLocale : { path: '/ingredients/add', method: "post", body: true, param: true, token: true },
        UpdateIngredientLocale : { path: '/ingredients/edit', method: "post", body: true, param: true, token: true },
        UsdaQuery : { path: '/ingredients/usda', method: "post", body: true, token: true },
        UsdaGet : { path: '/ingredients/usda', method: "get", param: true, token: true },

        CreateIngredient : { path: '/ingredients/add', method: "post", body: true, token: true },
        UpdateIngredient : { path: '/ingredients/edit', method: "post", body: true, param: true, token: true },
        DeleteIngredient : { path: '/ingredients/delete', method: "get",  param: true, token: true },

        ListPlanIngredients : {path: '/ingredients/plan', method: "get", token: true},
        CreatePlanIngredient: {path: '/ingredients/plan/add', method: "post", body: true, token: true},
        UpdatePlanIngredient: {path: '/ingredients/plan/edit', method: "post", body: true, param: true, token: true},
        DeletePlanIngredient: {path: '/ingredients/plan/delete', method: "post", param: true, token: true},


        ListPlanIngredientIcons : {path: '/ingredients/plan/icons', method: "get", token: true},
        CreatePlanIngredientIcon: {path: '/ingredients/plan/icons/add', method: "post", body: true, token: true},
        UpdatePlanIngredientIcon: {path: '/ingredients/plan/icons/edit', method: "post", body: true, param: true, token: true},
        DeletePlanIngredientIcon: {path: '/ingredients/plan/icons/delete', method: "post", param: true, token: true},
        UploadPlanIngredientIcon: {path: '/ingredients/plan/icons/upload', method: "post", body: true, param: true, token: true, upload: 'file'},

    // consumer

    // my plan
    GetMyPlan : { path: '/plan', method: "get", token: true},

    // groups
    ListPrefGroups : { path: '/pref-groups/list', method: "get", token: true },
    CreatePrefGroup : { path: '/pref-groups/add', method: "post", body: true, token: true },
    UpdatePrefGroup : { path: '/pref-groups/edit', method: "post", body: true, param: true, token: true },
    DeletePrefGroup : { path: '/pref-groups/delete', method: "get", param: true, token: true },
    GetPrefGroupIngredients : {path: '/pref-groups/ingredients', method: "get", param: true, token: true },

    ListRepGroups : { path: '/rep-groups/list', method: "get", token: true },
    CreateRepGroup : { path: '/rep-groups/add', method: "post", body: true, token: true },
    UpdateRepGroup : { path: '/rep-groups/edit', method: "post", body: true, param: true, token: true },
    DeleteRepGroup : { path: '/rep-groups/delete', method: "get", param: true, token: true },
    GetRepGroupIngredients : {path: '/rep-groups/ingredients', method: "get", param: true, token: true },


    ListTags : { path: '/tags/list', method: "get", token: true },
    ListServingSizes : { path: '/serving-sizes/list', method: "get", token: true },
    ListServingSizesLocale : { path: '/serving-sizes/list', method: "get", param: true, token: true },
    ListIngredients : {path: '/ingredients/list', method: "get", token: true },
    GetIngredientLocales : {path: '/ingredients/locales', method: "get", param: true, token: true },
};

export function ApiCall(endpoint, body = null, query = null, param = null) {

    // replace body for query and then to params to allow using 1st param for queries or params
    if (!endpoint.body && (endpoint.query || endpoint.param) && !query) { query = body; body = null; }
    if (!endpoint.query && endpoint.param && !param) { param = query; }

    let req = {
        body : {},
        method: endpoint.method,
        param: endpoint.param,
        url : '',
    };

    if (endpoint.body) {
        req.body = body;
    }

    let url = new URL(process.env.VUE_APP_API_URL + endpoint.path);
    if (endpoint.param) url += (url[url.length-1]==='/'?'':'/') + param;

    if (endpoint.query) {
        for (let i of endpoint.query) if (typeof query[i] !== "undefined") url.searchParams.append(i, query[i]);
    }

    if (endpoint.upload) {
        req.upload = endpoint.upload;
        if (!req.headers) req.headers = {}
        req.headers['Content-Type'] = "multipart/form-data";
    }

    if (endpoint.token) {
        const token = getToken();
        if (token) {
            if (!req.headers) req.headers = {};
            req.headers.token = token;
        }
    }

    req.url = url.toString();

    return req;
}

export const doRequest = async (uri, method = "GET", body = {}, additionalHeaders = {}, upload = '') => {

    let headers = new Headers();

    const params = {
        method, headers,
        credentials: 'include'
    };

    if (method.toLowerCase() === "post" || method.toLowerCase() === "put") {
        if (!additionalHeaders['Content-Type']) {
            headers.append('Content-Type','application/json');
        }
        params.body = JSON.stringify(body);
    }

    if (additionalHeaders) {
        for (let h in additionalHeaders)
            headers.append(h, additionalHeaders[h])
    }

    if (upload) {
        const data = new FormData();
        data.append(upload, body[upload])
        headers.delete('Content-Type')
        params.body = data;
    }

    try {
        const response = await fetch(uri, params);
        if (response.status === 404 || response.status === 400) {
            const resJson = await response.json();
            throw({fetchError : resJson});
        }
        else if (response.status === 200) {
            return await response.json();
        }
    } catch (fetchError) {
        throw({fetchError});
    }
};

export async function ApiRequest(call) {
    return await doRequest(call.url, call.method, call.body, call.headers, call.upload);
}
