<template>
  <div v-if="user" class="panel inline">
    <div class="panel-header">
      <p>User: <b>{{user.username}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <app-form :fields="fields" :data="user" :meta="{locales: allLocales, plans: allPlans, roles: allUserRoles}" @update="onUpdate" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ user.id?'Update User':'Create User' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import userFields from "@/lib/form-fields/user";
import AppForm from "@/components/ui/AppForm";

export default {

  components: { AppForm },

  data() {
    return {
      fields : userFields,
      user : null,
      locales : null,
      settingLocale : null
    }
  },

  name: "User",
  computed: {
    ...mapGetters(['isLoggedIn', 'allUsers', 'allUserRoles', 'allLocales','allPlans','savedUser']),

  },
  methods: {
    ...mapActions(['getUsers','getUserRoles', 'getLocales','getPlans', 'setLoading','setSuccess','setError','updateUser','createUser']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.user.id) { //update
        await this.updateUser([this.user, this.user.id])
      }
      else { // create new
        await this.createUser(this.user);
        if (this.savedUser.id) {
          await this.$router.push('/user/'+this.savedUser.id)
          await this.initData()
          this.user = {...this.savedUser }
        }
      }
      await this.setLoading(false);
    },

    onUpdate(values) {
      this.user = {...this.user, ...values};
    },

    async initData() {
      if (this.allUsers.length <= 0) await this.getUsers();
      if (this.allUserRoles.length <= 0) await this.getUserRoles();
      if (this.allLocales.length <= 0) await this.getLocales();
      if (this.allPlans.length <= 0) await this.getPlans();

      this.user = !isNaN(this.$route.params.id*1) ? this.allUsers.find(it => it.id === this.$route.params.id*1) :
          {username: "new-user", role_id: this.allUserRoles[0].id, locale_id: this.allLocales[0].id, plan_id: null }
    },
  },


  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        await this.initData()
      },800)
    }

    else await this.initData()
  },


}
</script>
