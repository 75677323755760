<template>
    <div class="panel inline">
      <ConfirmModal v-if="planForDeletion" item-type="Plan" :item-name="planForDeletion.name" :confirm="confirmDeletePlan" :cancel="cancelDeletePlan" />
      <ListPanel title="Plans" :fields="fields" :items="allPlans" :on-add="addNewPlan" :on-delete="askToDeletePlan" :on-row="goto" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import ConfirmModal from "@/components/ui/ConfirmModal";
import ListPanel from "@/components/ui/ListPanel";

export default {
  name: "Plans",
  components: {ListPanel, ConfirmModal },

  data() {
    return {
      planForDeletion: null,
      search : "",
      fields : [
        {label: "#", prop: "id", sortable: true, width: "60px" },
        {label: "Name", prop: "name", width: "20%", primary: true, sortable: true},
        {label: "Description", prop: "description", sortable: true},
        {label: "Actions", prop: "", width: "10%", actions: [
            {label: "x", type: "danger-btn", action: "delete", tooltip: "Delete"}
          ]}
      ]
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allPlans']),
  },

  methods: {
    ...mapActions(['getPlans','setLoading','deletePlan']),
    goto(plan) {
      this.$router.push('/plan/'+plan.id)
    },
    addNewPlan() {
      this.$router.push('/plan/new')
    },
    askToDeletePlan(plan) {
      this.planForDeletion = plan;
    },
    cancelDeletePlan() { this.planForDeletion = null },
    confirmDeletePlan() {
      this.deletePlan(this.planForDeletion.id)
      this.planForDeletion = null
    }
  },

  async mounted() {
    if (!this.isLoggedIn) {
      await this.setLoading(true)
      setTimeout(async () =>  {
        await this.setLoading(false)
        if (!this.isLoggedIn) return this.$router.push('/')
        if (this.allPlans.length <= 0) await this.getPlans();
      },800)
    }
    else if (this.allPlans.length <= 0) await this.getPlans();
  },
}
</script>
