<template>
  <div class="box">
    <div v-if="noResults">
      No suitable menu was found
    </div>
    <div v-if="!noResults" class="even">
      <div class="form-group raw" v-for="(meal, index) of meals" :key="index">
        <div v-if="meal" class="flexy col">
          <div class="flex-1">
            <div class=" between">
              <h3 class="u-lined">{{meal.name}}</h3>
              <span v-if="mealAlternativesFor(index)" class="info-btn" @click="chooseReplacement(index)">{{mealAlternativesFor(index).length}}</span>
            </div>
            <div v-if="meal.recipes">
              <div class="box" v-for="(r, index) in meal.recipes" :key="index">
                <h3>Recipe: <b>{{r.recipe.name}}</b></h3>
                <div v-for="ingredient in r.recipe.ingredients" :key="ingredient.id">
                  <div v-if="!ingredient.inactive">
                    <b class="bolder">{{ingredient.ingredient ? ingredient.ingredient.name : ingredient.name}}</b>
                    {{ingredient.serving_size_id ? ingredient.serving_amount + 'x '+ size(ingredient.serving_size_id) : ingredient.amount_g + 'g'}}
                  </div>
                </div>
              </div>
            </div>
            <div v-for="ingredient in meal.ingredients" :key="ingredient.id">
              <div v-if="!ingredient.inactive">
                <b class="bolder">{{ingredient.ingredient ? ingredient.ingredient.name : ingredient.name}}</b>
                {{ingredient.serving_size_id ? ingredient.serving_amount + 'x '+ size(ingredient.serving_size_id) : ingredient.amount_g + 'g'}}
              </div>
            </div>
          </div>
          <div class="box small bottom">
            <div class="flexy">
              <div>{{(nutrition(meal).calories)}} Calories</div>
              <div>{{(nutrition(meal).protein_g*4).toFixed(2)}} Protein</div>
              <div>{{(nutrition(meal).fat_g*9).toFixed(2)}} Fat</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showReplacements !== null">
      <h2>Replacements for {{meals[showReplacements].name}}</h2>
      <div class="even">
        <div class="box interactive" v-for="rep of this.mealAlternativesFor(showReplacements)" :key="rep.id" @click="onReplaceMeal(showReplacements, rep)">
          <h3><span class="glow-text">Replacement: <b>{{rep.name}}</b></span></h3>
          <div v-if="rep.recipes">
            <div class="box" v-for="(r, index) in rep.recipes" :key="index">
              <h3>Recipe: <b>{{r.recipe.name}}</b></h3>
              <div v-for="ingredient in r.recipe.ingredients" :key="ingredient.id" class="tight">
                <div v-if="!ingredient.inactive">
                  <b class="bolder">{{ingredient.ingredient ? ingredient.ingredient.name : ingredient.name}}</b>
                  {{ingredient.serving_size_id ? ingredient.serving_amount + 'x '+ size(ingredient.serving_size_id) : ingredient.amount_g + 'g'}}
                </div>
              </div>
            </div>
          </div>
          <div v-for="ingredient in rep.ingredients" :key="ingredient.id">
            <div v-if="!ingredient.inactive">
              <b class="bolder">{{ingredient.ingredient ? ingredient.ingredient.name : ingredient.name}}</b>
              {{ingredient.serving_size_id ? ingredient.serving_amount + 'x '+ size(ingredient.serving_size_id) : ingredient.amount_g + 'g'}}
            </div>
          </div>

          <div class="box small">
            <div class="form-group-content even">
              <div>Calories: <b class="bold">{{nutrition(rep).calories}}</b><br />offset: {{offset(rep, showReplacements,'calories')}} cal, {{percentOffset(rep, showReplacements,'calories')}}%</div>
              <div>Protein: <b class="bold">{{nutrition(rep).protein_g*4}}</b><br />offset: {{offset(rep, showReplacements,'protein_g', 4)}} cal, {{percentOffset(rep, showReplacements,'protein_g')}}%</div>
              <div>Fat: <b class="bold">{{nutrition(rep).fat_g*9}}</b><br />offset: {{offset(rep, showReplacements,'fat_g', 9)}} cal, {{percentOffset(rep, showReplacements,'fat_g')}}%</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mealNutrition} from "@/lib/NutritionUtils";
import {mapActions, mapGetters} from "vuex";
export default {
    props: ['meals','noResults','mealAlternatives','mealAlternativesGoals', 'replaceMeal'],
    data() {
        return {
          showReplacements : null
        }
    },
    computed : {
      ...mapGetters(['allServingSizes']),
    },
    methods: {
      ...mapActions(['getServingSizes']),

      chooseReplacement(index) {
        this.showReplacements = (this.showReplacements === index) ? null : index
      },

      mealAlternativesFor(index) {
        return (this.mealAlternatives && this.mealAlternatives[index].length > 0) ? this.mealAlternatives[index] : null
      },

      onReplaceMeal(index, newMeal) {
        this.showReplacements = null
        this.replaceMeal(index, newMeal)
      },

      nutrition(meal) {
        return mealNutrition(meal);
      },

      offset(meal1, goalIndex, prop, factor = 1) {
        return (mealNutrition(meal1)[prop]*factor - this.mealAlternativesGoals[goalIndex][prop]*factor).toFixed(2)*1
      },

      percentOffset(meal1, goalIndex, prop) {
        return ((mealNutrition(meal1)[prop] - this.mealAlternativesGoals[goalIndex][prop])/this.mealAlternativesGoals[goalIndex][prop]*100).toFixed(2)*1
      },

      size(id) {
        const s = this.allServingSizes.find(it => it.id === id)
        if (s) return s.name
        return `(${id})`
      }
    },
    async mounted() {
     if (this.allServingSizes.length <= 0) await this.getServingSizes(1);
    }
}
</script>
